<template>
  <footer class="Logged-footer">
    <div class="footer-links1">
      <router-link to="/"
            ><img src="@/assets/img/isotipowhite.svg" alt="Logo FloGuzman" />
          </router-link>
      <div class="footer-links1-text">
        <h1>Florencia Guzmán Irrazával</h1>
        <p>Todos los derechos reservados</p>
      </div>
    </div>
    <div class="footer-links2"></div>
    <div class="footer-links2"></div>
    <div class="footer-links5">
      <a href="https://www.instagram.com/flopiercer?igsh=MTh2cWc1Mng1NmtlMg==">
        <img src="@/assets/icons/instagram.svg" alt="Instagram" />
      </a>
      <a href="https://www.tiktok.com/@flopiercer">
        <img src="@/assets/icons/tiktok.svg" alt="Twitter" />
      </a>
    </div>
    <div class="footer-links3">
      <router-link to="/portafolio" style="text-decoration: none">
        <h3>Portafolio</h3>
      </router-link>
    </div>
    <div class="footer-links3">
      <router-link to="/cuidados" style="text-decoration: none">
        <h3>Cuidados</h3>
      </router-link>
    </div>
    <div class="footer-links3">
      <router-link to="/blog" style="text-decoration: none">
        <h3>Blog</h3>
      </router-link>
    </div>
    <div class="footer-links3">
      <router-link to="/contacto" style="text-decoration: none">
        <h3>Contacto</h3>
      </router-link>
    </div>
    <div class="footer-links3">
      <a
        href="https://linktr.ee/Flopiercer"
        style="text-decoration: none"
      >
        <h3>Reserva tu hora</h3>
      </a>
    </div>
  </footer>
</template>

<script>
// import { userServices } from '@/services/userServices';

export default {
  name: "MainFooter",
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style src="./MainFooter.css"></style>
