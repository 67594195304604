<template>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <p>Cuidados de una perforación</p>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div class="faq-icons">
    <div class="faq-icon-container">
      <div class="faq-icon-border-gold">
        <img src="@/assets/icons/botiquin.svg" alt="" />
      </div>
      <p><u>Limpia 1 vez al día</u> con una solución salina estéril</p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-gold">
        <img src="@/assets/icons/calendar.svg" alt="" />
      </div>
      <p><u>Asistir a control</u> cuando te lo indique tu perforador</p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-gold">
        <img src="@/assets/icons/apple.svg" alt="" />
      </div>
      <p>
        <u>Buena alimentación e hidratación</u>, evita fumar y el consumo de
        alcohol o drogas
      </p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-gold">
        <img src="@/assets/icons/hand.svg" alt="" />
      </div>
      <p><u>Lava muy bien tus manos</u> antes de tocar tu piercing</p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-red">
        <img src="@/assets/icons/red-hand.svg" alt="" />
      </div>
      <p>
        <u>Evita tocar</u>, golpear, tironear y/o jugar con
        <u>tu perforación</u> y joyería
      </p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-red">
        <img src="@/assets/icons/bed.svg" alt="" />
      </div>
      <p>
        <u>No dormir encima de tu perforación</u> durante el tiempo que de
        indique tu perforador
      </p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-red">
        <img src="@/assets/icons/red-piercing.svg" alt="" />
      </div>
      <p>
        <u>No saques, o trates de cambiar la joyería</u> antes de que te lo
        recomiende tu perforador
      </p>
    </div>
    <div class="faq-icon-container">
      <div class="faq-icon-border-red">
        <img src="@/assets/icons/red-drop.svg" alt="" />
      </div>
      <p>No utilices nada aparte de <u>agua y suero fisiológico para limpiar</u> tu perforación</p>
    </div>
  </div>
  <div class="about-first-section">
    <div class="first-section-texts">
      <div class="first-section-title">
        <h1>¿Como cuidar una perforación nueva?</h1>
      </div>
      <div class="first-section-paragraphs">
        <div class="first-paragraph">
          <p>
            <u>Los dos primeros días</u> mantendremos la perforación seca,
            podemos bañarnos y lavarnos el pelo normalmente, pero nos
            preocuparemos de enjuagar con abundaste agua tibia y secarlo con
            algo limpio desechable (gasa estéril, toalla de papel, pañuelitos).
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Es normal que al principio de una perforación veremos un poco de
            inflamación, sensibilidad al tacto, un leve enrojecimiento y
            sangrado. Cuando aparezcan las costras de sangre, dejaremos que
            salgan con la ducha y una vez que lleguemos al segundo día post
            perforación, podemos ir limpiando con suavidad y precaución, ya que,
            si la costra todavía está muy adherida a la herida, puede que, al
            sacarla, vuelva a sangrar. Si es que pasara esto, deja la costra por
            unos 3 a 4 días y/o comunicate con tu perforador.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Una vez pasado los primeros 3 días comenzaremos a limpiar nuestro
            piercing. Antes de comenzar la limpieza, nos vamos a preocupar
            SIEMPRE de lavar muy bien nuestras manos antes de tocar nuestra
            perforación. Con una solución salina estéril (NeilMed piercing
            aftercare o Fisiolimp) y gasas estériles o toallas de papel. La limpieza la
            haremos en forma de semicírculo, por delante y por detrás o por
            arriba y abajo. Una vez hecha la limpieza, secaremos con una gasa
            estéril o toalla de papel.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Después de una semana aproximadamente, comenzará a salir una costra
            amarillenta translúcida llamada plasma sanguíneo, células muertas,
            etc. Esas costras dejarán tu perforación pegada y es ahí donde nace
            el mito de <u>“gira tu perforación para que no se pegue”</u>, esto NO se
            debe hacer por ningún motivo, solo debes preocuparte de realizar la
            limpieza diaria.
          </p>
        </div>
      </div>
    </div>
    <div class="about-first-image">
      <img src="@/assets/img/cuidados1.jpg" alt="" />
    </div>
  </div>
  <div class="about-first-section">
    <div class="about-second-image">
      <img src="@/assets/img/cuidados2.jpg" alt="" />
    </div>
    <div class="second-section-texts">
      <div class="first-section-paragraphs">
        <div class="first-paragraph">
          <h1>¿Qué es normal?</h1>
          <p>
            Es normal durante las primeras semanas presentar un poco de dolor,
            sangrado, inflamación, enrojecimiento y posibles moretones. También
            es normal que una semana después de tu perforación, aparezcan una
            secreción amarillenta translúcida, a esto se le denomina plasma
            sanguíneo, células muertas, etc.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Las perforaciones pueden lucir cicatrizadas antes del tiempo que te
            indique tu perforador, pero esto no es así. Éstas cicatrizan de
            afuera hacia adentro, o sea que, aunque se vean sanas por fuera, no
            significa que lo estén por dentro. Si tienes dudas de cómo va tu
            cicatrización, agenda una cita de control con tu perforador.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Una vez pasado los primeros 2 días comenzaremos a limpiar nuestro
            piercing. Antes de comenzar la limpieza, nos vamos a preocupar
            SIEMPRE de lavar muy bien nuestras manos antes de tocar nuestra
            perforación. Con una solución salina estéril (NeilMed piercing
            aftercare o Fisiolimp) y gasas estériles o toallas de papel. La limpieza la
            haremos en forma de semicírculo, por delante y por detrás o por
            arriba y abajo. Una vez hecha la limpieza, secaremos con una gasa
            estéril o cotonito.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Es importante recalcar que cada cuerpo es único y los tiempos de
            cicatrización, varían considerablemente.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>¿Qué NO es normal?</h1>
          <p>
            Mucho dolor, sentir muy caliente o afiebrada la zona perforada,
            exceso de sangrado, pus amarillento o verdoso, mal olor, sentir la
            perforación muy inflamada.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Si los síntomas iniciales, empeoran o siguen durante más de dos
            días, visita inmediatamente a tu perforador.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>¿Cuándo asistir a control?</h1>
          <p>
            El primer control en su mayoría siempre es después de las primeras 6
            semanas post perforación y a los 3 meses con la perforación, pero en
            caso de que necesites ir alguna urgencia, puedes ir sin ningún
            problema.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>¿Qué NO hacer?</h1>
          <ul>
            <li>No juegues con tu joyería ni toques tu perforación.</li>
            <li>Girar la joyería de tu perforación cuando se pegue.</li>
            <li>
              No sumerjas tu perforación en aguas contaminadas: piscinas, mar,
              ríos, lagos, termas, jacuzzi, etc.
            </li>
            <li>
              No duermas sobre tu perforación hasta el tiempo que te indique tu
              perforador.
            </li>
            <li>
              Si es que tienes un examen médico que requiera quitar la joyería,
              visita a tu perforador para que te ayude a retirarla y para que
              vuelva a colocarla una vez terminado el examen. Es vital que lo
              haga un profesional, ya que puedes dañar y/o perjudicar tu
              perforación.
            </li>
            <li>
              Limpiar la perforación de forma excesiva o muy efusivamente.
            </li>
            <li>
              Si vas a ir a la peluquería y quieres tener una perforación en la
              oreja, anda antes del procedimiento ya que los productos la pueden
              dañar gravemente o puede haber tirones del profesional sin querer.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="about-third-section">
    <div class="third-section-texts">
      <div class="first-section-title">
        <h1>Peforaciones bucales</h1>
      </div>
      <div class="first-section-paragraphs">
        <div class="first-paragraph">
          <p>
            Las perforaciones bucales necesitan tanto limpiezas internas como
            externas (excluyendo la perforación de lengua).
          </p>
        </div>
        <div class="first-paragraph">
          <h1>Limpieza interna</h1>
          <p>
            La limpieza interna parte desde el primer día a diferencia de la
            externa. Antes de comenzar la limpieza, nos vamos a preocupar
            SIEMPRE de lavar muy bien nuestras manos antes de tocar nuestra
            perforación. Una vez hecho esto, vamos a utilizar un enjuague bucal
            SIN ALCOHOL y agua (ojalá embotellada o filtrada). Haremos la
            siguiente mezcla; 50% de enjuague bucal sin alcohol y 50% de agua, y
            haremos enjuagues bucales unas 3 veces al día. También lavaremos
            nuestros dientes después de cada comida con la MITAD de la pasta de
            dientes que ocupamos generalmente (poca pasta de dientes). A nuestra
            limpieza podemos agregarle hilo dental para retirar los restos de
            comida que quedan en nuestra boca y que es más difícil de sacar. Al
            cepillarse hay que tener cuidado de hacerlo de una manera suave y
            cuidadosa (evitando cepillar la zona perforada).
          </p>
        </div>
        <div class="first-paragraph">
          <h1>Limpieza externa</h1>
          <p>
            Los primeros 2 días sólo enjuagaremos con agua tibia en la ducha y
            secaremos con un papel absorbente desechable (toalla de papel,
            pañuelos, gasas estériles). Pasado esos 3 días, limpiaremos con una
            solución salina estéril una vez al día y con una gasa estéril o
            cotonito, haciendo semi círculo y luego secamos.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>¿Qué es normal?</h1>
          <p>
            Sentir inflamación, sangrado, una secreción blanquecina, un poco de
            dolor, hematomas y sensibilidad en la zona. Para las perforaciones
            de labio, es normal que la joya por dentro se vea muy apegada al a
            la piel.. En caso de que sientas alguno de estos síntomas en exceso,
            debes contactarte con tu perforador inmediatamente.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>¿Qué NO es normal?</h1>
          <p>
            Experimentar cualquier de los síntomas normales en exceso y de
            manera continua.
          </p>
        </div>
        <div class="first-paragraph">
          <h1>Recomendaciones importantes</h1>
          <ul>
            <li>No juegues con tu joyería ni toques tu perforación.</li>
            <li>Girar la joyería de tu perforación cuando se pegue.</li>
            <li>
              No sumerjas tu perforación en aguas contaminadas: piscinas, mar,
              ríos, lagos, termas, jacuzzi, etc.
            </li>
            <li>
              No duermas sobre tu perforación hasta el tiempo que te indique tu
              perforador.
            </li>
            <li>
              Si es que tienes un examen médico que requiera quitar la joyería,
              visita a tu perforador para que te ayude a retirarla y para que
              vuelva a colocarla una vez terminado el examen. Es vital que lo
              haga un profesional, ya que puedes dañar y/o perjudicar tu
              perforación.
            </li>
            <li>
              Limpiar la perforación de forma excesiva o muy efusivamente.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="about-third-image">
      <img src="@/assets/img/cuidados3.jpg" alt="" />
      <img src="@/assets/img/cuidados4.jpg" alt="" />
    </div>
  </div>
  <div class="about-forth-section">
    <div class="forth-section-texts">
      <div class="first-section-title">
        <h1>Perforaciones Genitales</h1>
      </div>
      <div class="first-section-paragraphs">
        <div class="first-paragraph">
          <p>
            Es esencial seguir algunas advertencias importantes para el cuidado
            adecuado de tus piercings genitales. Primero, asegúrate de lavarte
            bien las manos antes de tocar la perforación para evitar la
            introducción de bacterias. Durante los primeros días, es normal que
            la perforación sangre, así que prepárate y no te asustes. Aunque no
            es necesario limpiarte después de orinar, se recomienda mantener una
            buena higiene en todo momento.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Cuando te sientas listo para tener actividad sexual, ten en cuenta
            que durante el periodo de cicatrización, es fundamental evitar
            brusquedades y realizar todas las acciones con suavidad. Siempre
            utiliza condones para toda relación sexual y opta por lubricantes a
            base de agua para prevenir irritaciones.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Después de la actividad sexual, asegúrate de hacer un lavado
            adecuado para mantener la zona limpia y libre de infecciones.
            Recuerda que cada individuo es responsable de su propia salud y
            bienestar, así que toma las precauciones necesarias para garantizar
            una correcta cicatrización y cuidado de tus piercings genitales.
          </p>
        </div>
        <div class="first-paragraph">
          <p>
            Si ocurre algo inesperado o tienes alguna situación, comunícate
            inmediatamente con tu perforador.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <p>Preguntas Frecuentes</p>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div >
    <FaqCard v-for="faq in filteredFaqs" :key="faq.id" :detail="faq" />
  </div>
</template>

<script>
import { faqServices } from "@/services/faqServices";
import FaqCard from "@/components/FaqCards/FaqCard.vue";

export default {
  name: "CuidadosPreguntas",
  components: {
    FaqCard,
  },
  data() {
    return {
      faqs: [],
    };
  },
  created() {
    this.listFaqs();
  },
  computed: {
    filteredFaqs() {
      return this.faqs; // Return the faqs array directly
    }
  },
  mounted() {
  },
  methods: {
    listFaqs(params) {
      faqServices.list(params)
      .then((response) => {
        this.faqs = response;
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>

<style scoped>
.faq-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem; 
  margin-left: 6rem;
  margin-right: 6rem;
  width: auto;
}
.faq-icon-container {
  display: flex;
  width: auto;
  padding: 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}
.faq-icon-border-gold {
  display: flex;
  width: 6rem;
  height: 6rem;
  padding: 1.095rem 1.09438rem 1.09288rem 1.095rem;
  justify-content: center;
  align-items: center;
  border-radius: 12.5rem;
  border: 3px solid var(--primary-gold, #9e7338);
}
.faq-icon-border-red {
  display: flex;
  width: 6rem;
  height: 6rem;
  padding: 1.095rem 1.09438rem 1.09288rem 1.095rem;
  justify-content: center;
  align-items: center;
  border-radius: 12.5rem;
  border: 2px solid var(--error, #fc4550);
}
.faq-icon-border-gold img {
  width: 3.62294rem;
  height: 3.73694rem;
  flex-shrink: 0;
}
.faq-icon-border-red img {
  width: 3.62294rem;
  height: 3.73694rem;
  flex-shrink: 0;
}
.faq-icon-container p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}
.faq-icon-container u {
  text-decoration: none;
  color: var(--black-black-10, #e7e7e7);
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 1.4625rem */
}
.about-first-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  margin-bottom: 4rem;
}
.about-third-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  margin-bottom: 4rem;
}
.about-forth-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  height: auto;
}
.first-section-texts {
  width: 39.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.second-section-texts {
  width: 39.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.third-section-texts {
  width: 39.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.forth-section-texts {
  width: 87%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.first-section-title {
  display: flex;
  justify-content: flex-start;
}
.first-section-title h1 {
  color: var(--primary-primary-light, #cfa869);
  text-align: right;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.05625rem;
}
.first-section-paragraphs {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.first-paragraph {
  display: flex;
  flex-direction: column;
  text-align: justify;
  height: 100%;
  margin-bottom: 0.5rem;
}
.first-paragraph p {
  color: var(--black-black-20, #cdcdce);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 170%; /* 1.4875rem */
}
.first-paragraph u {
  text-decoration: none;
  color: var(--primary-primary, #9e7338);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%; /* 1.4875rem */
}
.first-paragraph h1 {
  color: var(--primary-primary, #9e7338);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%; /* 1.4875rem */
  margin-bottom: 0.5rem;
}
.first-paragraph ul {
  list-style: none;
}

.first-paragraph li {
  margin: 0;
  padding-left: 1.5em;
  position: relative;
  color: var(--black-black-20, #cdcdce);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 170%; /* 1.4875rem */

  &::after {
    content: "";
    height: 0.4em;
    width: 0.4em;
    background: rgba(158, 115, 56, 1);
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: 0.7em;
    left: 0;
  }
}
.about-first-image {
  width: 39.25rem;
  height: 35.175rem;
}
.about-second-image {
  width: 39.25rem;
  height: 61.125rem;
}
.about-third-image {
  width: 39.25rem;
  height: 77.0625rem;
}
.about-third-image img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  filter: brightness(1.1) saturate(0.9) contrast(1);
  overflow: hidden;
}
.about-second-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1.1) saturate(0.9) contrast(1);
  overflow: hidden;
}
.about-first-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1.1) saturate(0.9) contrast(1);
  overflow: hidden;
}
@media screen and (max-width:26.875rem) {
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    margin-bottom: 2rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .first-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .first-paragraph u {
    text-decoration: none;
    color: var(--primary-primary, #9e7338);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%; /* 1.4875rem */
  } 
  .first-paragraph h1 {
    color: var(--primary-primary, #9e7338);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%; /* 1.4875rem */
    margin-bottom: 0.5rem;
  }
  .first-paragraph ul {
    list-style: none;
  }
  .first-paragraph li {
    margin: 0;
    padding-left: 1.5em;
    position: relative;
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
    &::after {
      content: "";
      height: 0.4em;
      width: 0.4em;
      background: rgba(158, 115, 56, 1);
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 0.7em;
      left: 0;
    }
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image img {
    width: 90%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-image img {
    width: 90%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-first-image img {
    width: 90%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width:20rem){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-third-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    margin-bottom: 2rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    height: auto;
  }
  .first-section-texts {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-first-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width:1440px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 1rem 1rem 1rem;
  }
  .about-third-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin: 1rem 1rem 1rem 1rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    height: auto;
  }
  .first-section-texts {
    width:70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image {
    width: 100%;
    height: 100%;
  }
  .about-third-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-first-image img {
    width: 80%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width:1024px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

  }
  .about-first-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  
}
@media screen and (max-width:768px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  .about-first-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 1rem;
  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
  .about-first-image, .about-second-image, .about-third-image {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .about-first-image img, .about-second-image img, .about-third-image img {
    width: 48%;
  }

}
@media screen and (max-width:425px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width:375px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width:320px){
  .faq-icons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: auto;
  }
  .faq-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .about-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .about-forth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: auto;
  }
  .first-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .second-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .third-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .forth-section-texts {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    flex-direction: column;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
    margin-bottom: 0.5rem;
  }
}
</style>
