<template>
  <div class="testimonios-home">
    <div class="testimonios-home-title">
      <button @click="prevPage">
        <img src="@/assets/icons/arrow-left.svg" alt="" />
      </button>
      <h1>Testimonios</h1>
      <p>de clientes</p>
      <button @click="nextPage">
        <img src="@/assets/icons/arrow-right.svg" alt="" />
      </button>
    </div>
    <transition name="fade">
      <div class="testimonios-cards">
        <TestimoniosCard v-for="(testimonio, index) in paginatedTestimonios" :key="index" :testimonio="testimonio" />
      </div>
    </transition>
  </div>
</template>

<script>
import TestimoniosCard from "@/components/TestimoniosCard/TestimoniosCard.vue";
import { blogServices } from "@/services/blogServices";

export default {
  name: "TestimoniosHome",
  components: {
    TestimoniosCard,
  },
  data() {
    return {
      testimonios: [],
      currentPage: 1,
      itemsPerPage: 8, // Default to 10 items per page (2 rows of 5 items each)
    };
  },
  mounted() {
    this.listTestimonios('Testimony');
    this.$nextTick(() => {
      this.calculateItemsPerPage();
      window.addEventListener('resize', this.calculateItemsPerPage);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateItemsPerPage);
  },
  computed: {
    paginatedTestimonios() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.testimonios.slice(startIndex, endIndex);
    },
  },
  methods: {
    listTestimonios(type) {
      const params = { type };

      blogServices
        .list_blogs(params)
        .then((response) => {
          this.testimonios = response.data;
          this.calculateItemsPerPage(); // Recalculate items per page based on the new data
        })
        .catch((error) => {
          console.error("Error fetching testimonies:", error);
        });
    },
    nextPage() {
      if (this.currentPage < Math.ceil(this.testimonios.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    calculateItemsPerPage() {
      const container = document.querySelector('.testimonios-home');
      if (container) {
        const containerWidth = container.offsetWidth;

        // Estimate card width based on a middle value between the minimum and maximum
        const cardWidth = 400; // Assumed average card width, adjust if necessary
        const itemsPerRow = Math.floor(containerWidth / cardWidth);

        // Set items per page to be 2 rows worth of items
        this.itemsPerPage = itemsPerRow * 2;
      }
    },
  },
};
</script>

<style src="./TestimoniosHome.css"></style>