<template> 
    <div class="slider-title-spacing">
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear">
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear">
      </div>
        <div class="slider-titles">
            <p>Mis servicios</p>
        </div>
        <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear">
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear">
      </div>
    </div>
    <div class="service-cards">
      <div class="service-card">
        <div class="service-card-title">
          <h1>Perforaciones corporales</h1>
        </div>
        <div class="service-card-image"
          @mouseenter="function1('service1')"
          @mouseleave="function2('service1')">
          <router-link to="/portafolio" style="text-decoration: none;">
            <img src="@/assets/img/servicios1.png" alt="perforaciones-corporales">
          <div v-if="isHovered['service1']">
              <div class="inside-texts">
                <h1>Ver Portafolio</h1>
              </div>
          </div>
          </router-link>
        </div>
        <div class="service-card-desc">
          <p>Realizo perforaciones corporales de 
            <u>manera segura y profesional</u>, te asesoraré tomando en cuenta tus gustos,
            <u>tu estilo y tu anatomía</u>
            para que la perforación sea viable y duradera en el tiempo.</p>
        </div>
      </div>
      <div class="service-card">
        <div class="service-card-title">
          <h1>Venta y cambio de joyería</h1>
        </div>
        <div class="service-card-image" 
          @mouseenter="function1('service2')"
          @mouseleave="function2('service2')">
          <router-link to="/contacto" style="text-decoration: none;">
            <img src="@/assets/img/servicios2.png" alt="perforaciones-corporales">
            <div v-if="isHovered['service2']" >
              <div class="inside-text">
                <h1>Ir a Contacto para comprar joyería</h1>
              </div>
            </div>
          </router-link>
        </div>
        <div class="service-card-desc">
          <p>El uso de una 
            <u>joyería de calidad </u>
            es sumamente importante. Trabajo con joyería de 
            <u>titanio grado implante certificado ASTM-F136, Niobio y oro sólido de 14k y 18k</u>
            . Además te ayudaré con la
            <u>instalación de tu joyería</u>
            , ya que en un inicio puede ser complejo y puedes dañar tu perforación.</p>
        </div>
      </div>
      <div class="service-card">
        <div class="service-card-title">
          <h1>Controles post-perforaciones y externos</h1>
        </div>
        <div class="service-card-image" 
          @mouseenter="function1('service3')"
          @mouseleave="function2('service3')">
          <a href="https://linktr.ee/Flopiercer" style="text-decoration: none;">
            <img src="@/assets/img/servicios3.png" alt="perforaciones-corporales">
          <div v-if="isHovered['service3']" >
            <div class="inside-texts">
              <h1>Agendar hora</h1>
            </div>
          </div>
          </a>
        </div>
        <div class="service-card-desc">
          <p>Es importante 
            <u>hacerle seguimiento</u>
            a tu proceso de cicatrización y así poder ayudarte de manera oportuna en caso de que lo necesites. 
            <u>Realizo controles externos</u>
            , vale decir, que te los hayas hecho con otro perforador o en otro establecimiento.</p>
        </div>
      </div>
    </div>
    
</template>

<script>

export default {
    name: "ServiciosCards",
    components: {
    },
    data() {
        return {
          isHovered: {
            service1: false,
            service2: false,
            service3: false,
          },
        };
    },
    created() {
    },
    methods: {
        function1(service) {
          this.isHovered[service] = true;
        },
        function2(service) {
          this.isHovered[service] = false;
        },

    },
};

</script>

<style src="./ServiciosCards.css"></style>