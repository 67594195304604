<template>
  <div>
    <div class="blog-title">
      <div class="title-texts">
        <h1>Testimonios</h1>
        <div class="vertical-line"></div>
        <router-link to="/blog" style="text-decoration: none">
          <p>Blog</p>
        </router-link>
      </div>
      <div class="blog-button" v-if="isLogged">
        <router-link to="/testimonio_upload" style="text-decoration: none">
          <button>
            <h1>+ Subir testimonio</h1>
          </button>
        </router-link>
      </div>
    </div>
    <div class="testimonios-container">
      <TestimoniosCard v-for="(testimonio, index) in testimonios" :key="index" :testimonio="testimonio" />
    </div>
  </div>
</template>

<script>
import TestimoniosCard from "@/components/TestimoniosCard/TestimoniosCard.vue";
import { blogServices } from "@/services/blogServices";

export default {
  name: "TestimonioView",
  components: {
    TestimoniosCard,
  },
  props: {},
  computed: {
    isLogged: function () {
      if (this.$store.state.accessToken !== null) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.listTestimonios('Testimony');
  },
  data() {
    return {
      testimonios: [], 
    };
  },
  methods: {
    listTestimonios(type) {
      const params = { type };

      blogServices
        .list_blogs(params)
        .then((response) => {
          console.log("Testimonios response:", response);
          this.testimonios = response.data; 
        })
        .catch((error) => {
          console.error("Error fetching testimonies:", error);
        });
    }
  }
};
</script>

<style scoped>
.vertical-line {
  width: 0.09375rem;
  height: 1.6875rem;
  background: #9e7338;
}
.blog-title {
  display: flex;
  width: 81.5rem;
  padding: 1.1875rem 0rem;
  justify-content: space-between;
  align-items: center;
  margin-left: 6.5rem;
}
.title-texts {
  display: flex;
  align-items: center;
  gap: 1.125rem;
}
.title-texts h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
}
.title-texts p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.03375rem;
  transition: all 0.3s ease-in-out;
}
.title-texts p:hover {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
  transition: all 0.3s ease-in-out;
}
.title-texts button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-primary, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}
.title-texts button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.search-button button {
  display: flex;
  width: 13.4375rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
}

.search-button button h1 {
  color: var(--black-black-60, #6a6a6d);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.testimonios-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}
.blog-button button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-primary, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}
.blog-button button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
@media screen and (max-width: 26.875rem) {
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    gap: 1.5rem;
  }
  .search-button {
    display: none;
  }
  .testimonios-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width:20rem){
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    gap: 1.5rem;
  }
  .search-button {
    display: none;
  }
  .testimonios-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
