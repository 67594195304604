<template>
  <div>
    <div class="Login-form">
      <h1>Acceso administrador</h1>
    </div>
    <div class="login-container">
      <div class="mail-form">
        <h1>CORREO ELECTRONICO</h1>
        <input v-model="username" type="text" name="email" id="email" />
      </div>
      <div class="password-form">
        <h1>CONTRASEÑA</h1>
        <form action="" @submit.prevent>
          <div class="eyeforms">
            <input
              @keyup.enter="onPressEnter"
              v-model="password"
              type="password"
              name="password"
              id="password"
              class="pass-background"
            />
          </div>
        </form>
      </div>
      <div class="login-error">
        <p>{{ this.message }}</p>
      </div>
      <button @click="login()">
        <h1>INICIAR SESION</h1>
      </button>
    </div>
  </div>
</template>

<script>
import { loginServices } from "@/services/loginServices.js";

export default {
  name: "inputLogin",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      accessToken: "", // To store the access token
      message: "",
    };
  },
  computed: {},
  methods: {
    login() {
      loginServices
        .login(this.username, this.password)
        .then((token) => {
          this.accessToken = token;
          console.log("Logged in with token:", token);
          this.$store.dispatch("updateAccessToken", token);
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          console.error("Login error:", error);
          if (error.response.status === 401) {
            this.message =
              "Correo electrónico inválido o Contraseña incorrecta";
          } else {
            this.message = "Error al iniciar sesión";
          }
        });
    },
    onPressEnter(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    },
    handleClick() {
      this.eyeClicked = !this.eyeClicked;
      if (this.eyeClicked) {
        document.getElementById("password").type = "text";
      } else {
        document.getElementById("password").type = "password";
      }
    },
  },
};
</script>

<style src="./LoginForm.css"></style>
