<template>
  <nav ref="navbar">
    <div class="nav-inside">
      <div class="col-6">
        <div class="dropdown-mobile" :class="{ active: isDropdownOpen }">
          <button @click="toggleDropdown">
            <img src="@/assets/icons/dropdownmenu.svg" alt="" />
          </button>
          <div class="dropdown-content">
            <ResponsiveMenu :isOpen="isDropdownOpen" @close="closeDropdown" />
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/img/logoOro01.png" alt="Logo FloGuzman"
          /></router-link>
        </div>
      </div>
      <div :class="{'col-7': !isSearchVisible, 'hidden': isSearchVisible}">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/img/Logo-mobile.png" alt="Logo FloGuzman"
          /></router-link>
        </div>
      </div>
      <div class="col-1">
        <div class="menu-item">
          <router-link to="/portafolio" style="text-decoration: none">
            <h3>Portafolio</h3>
          </router-link>
        </div>
      </div>
      <div class="col-1">
        <div class="menu-item">
          <router-link to="/cuidados" style="text-decoration: none">
            <h3>Cuidados · Preguntas</h3>
          </router-link>
        </div>
      </div>
      <div class="col-1">
        <div class="menu-item">
          <router-link to="/blog" style="text-decoration: none">
            <h3>Blog</h3>
          </router-link>
        </div>
      </div>
      <div class="col-1">
        <div class="menu-item">
          <router-link to="/about" style="text-decoration: none">
            <h3>Sobre mi</h3>
          </router-link>
        </div>
      </div>
      <div class="col-1">
        <div class="menu-item">
          <router-link to="/contacto" style="text-decoration: none">
            <h3>Contacto</h3>
          </router-link>
        </div>
      </div>
      <div class="col-2" ref="searchContainer">
        <div class="button" v-if="isSearchVisible" ref="searchBar">
          <search @close="closeSearchBar" @search="search">
            <button>
              <input
                type="text"
                v-model="input"
                placeholder="Buscar"
                @keyup.enter="search"
              />
              <img
                src="@/assets/icons/lupa.svg"
                alt=""
                class="browser"
                @click="search"
              />
            </button>
          </search>
        </div>
        <div class="button" v-else>
          <button @click="toggleSearchBar">
            <img src="@/assets/icons/lupa.svg" alt="" class="nbrowser" />
          </button>
        </div>
      </div>
      <div class="col-5">
        <div class="button-reserva">
          <a
            href="https://linktr.ee/Flopiercer"
            style="text-decoration: none"
          >
            <button type="submit">Reserva tu hora</button>
          </a>
        </div>
        <div class="col-5">
          <div class="button-reservas">
            <a
              href="https://linktr.ee/Flopiercer"
              style="text-decoration: none"
            >
              <button type="submit">
                <img
                  src="@/assets/icons/appointment-gold.svg"
                  alt="reserva tu hora"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ResponsiveMenu from "@/components/ResponsiveMenu/ResponsiveMenu.vue";
import { searchServices } from "@/services/searchServices";

export default {
  components: {
    ResponsiveMenu,
  },
  data() {
    return {
      isDropdownOpen: false,
      isClicked: false,
      isSearchVisible: false,
      input: "",
    };
  },
  props: {},
  computed: {},
  methods: {
    handleIsAdmin() {
      if (this.$store.state.user.role === "admin") {
        this.isAdmin = true;
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen; 
    },
    closeDropdown() {
      this.isDropdownOpen = false; 
    },
    toggleSearchBar() {
  this.isSearchVisible = !this.isSearchVisible;
  if (this.isSearchVisible) {
    document.addEventListener("click", this.handleClickOutside);
  } else {
    document.removeEventListener("click", this.handleClickOutside);
  }
},
    search() {
      searchServices
        .search(this.input)
        .then((response) => {
          this.$router.push({
            path: "/busqueda",
            query: { q: this.input, results: JSON.stringify(response) },
          });
        })
        .catch((error) => {
          console.log("Error searching:", error);
        });
    },
    handleClickOutside(event) {
  if (
    this.isSearchVisible &&
    this.$refs.searchContainer &&
    !this.$refs.searchContainer.contains(event.target) &&
    !event.target.closest(".button")
  ) {
    this.closeSearchBar();
  }
},
    closeSearchBar() {
      this.isSearchVisible = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    
  },
};
</script>

<style src="./NavBar.css"></style>
