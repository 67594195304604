<template>
  <div>
    <div class="blog-title">
      <div class="title-texts">
        <h1>Blog</h1>
        <div class="vertical-line"></div>
        <router-link to="/testimonios" style="text-decoration: none">
          <p>Testimonios</p>
        </router-link>
      </div>
    </div>
    <div class="blog-entry">
      <div>
        <div class="entry-title">
          <h1>{{ blog.title }}</h1>
        </div>
        <div class="entry-text">
          <p v-html="blog.description"></p>
        </div>
        <div class="entry-text">
          <p>{{ blog.hyperlink }}</p>
        </div>
        <div class="entry-date">
          <h1>Publicado el {{ formatDate(blog.created_at) }}</h1>
        </div>
      </div>
      <div class="entry-img">
        <img :src="blog.signed_url" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { blogServices } from '@/services/blogServices';

export default {
  name: "BlogDetail",
  data() {
    return {
      blog: {},
    };
  },
  created() {
    this.getBlog();
  },
  methods: {
  getBlog() {
    const id = this.$route.params.id;
    blogServices.retrieve_blog({ id })
      .then((response) => {
        // Asegúrate de acceder a los datos correctamente
        if (response && response.detail) {
          this.blog = response.detail;
        } else {
          console.error('No blog found with ID:', id);
        }
      })
      .catch((error) => {
        console.error('Error fetching blog:', error);
      });
  },
  formatDate(dateString) {
    return new Date(dateString).toLocaleDateString();
  },
},
};
</script>

<style scoped>
.vertical-line {
  width: 0.09375rem;
  height: 1.6875rem;
  background: #9e7338;
}

.blog-title {
  display: flex;
  width: 100%;
  padding: 1.1875rem 0rem;
  justify-content: space-between;
  align-items: center;
  margin-left: 6.5rem;
}

.title-texts {
  display: flex;
  align-items: center;
  gap: 1.125rem;
}

.title-texts h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
}

.title-texts p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.03375rem;
  transition: all 0.3s ease-in-out;
}

.title-texts p:hover {
  color: var(--primary-gold, #9e7338);
  transition: all 0.3s ease-in-out;
}

.title-texts button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-primary, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}

.title-texts button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}

.search-button button {
  display: flex;
  width: 12.4375rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}

.search-button button h1 {
  color: var(--black-black-60, #6a6a6d);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}

.blog-entry {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 81.5rem;
  height: auto;
  flex-shrink: 0;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  margin-left: 6.5rem;
  margin-bottom: 2rem;
}

.entry-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 2rem;
  margin-left: 1.25rem;
  margin-bottom: 2rem;
}

.entry-title h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.625rem */
  transition: all 0.3s ease-in-out;
}

.entry-title h1:hover {
  color: var(--primary-gold, #9e7338);
  transition: all 0.3s ease-in-out;
}

.entry-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
  width: 38.125rem;
  flex-shrink: 0;
  margin-left: 1.25rem;
  text-overflow: ellipsis;
  text-align: justify;
  text-justify: inter-word;
  overflow: hidden;
}

.entry-text p {
  color: var(--black-black-20, #cdcdce);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}

.entry-text ul {
  color: var(--black-black-20, #cdcdce);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}

.entry-text ul li {
  margin-left: 1.25rem;
}

.entry-date {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.625rem;
  margin-top: 3rem;
  margin-left: 1.25rem;
  margin-bottom: 2rem;
}

.entry-date h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.05625rem */
}

.entry-img {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-shrink: 0;
}

.entry-img img {
  width: 35.0625rem;
  height: 30.625rem;
  object-fit: cover;
  border: 1px solid var(--black-black-80, #38383d);
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  .blog-title {
    width: 95%;
    margin-left: 0;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .blog-entry {
    width: 95%;
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .blog-title {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
  }
  .blog-entry {

    width: 95%;
    height: auto;
    margin-left: 1rem;
    padding: 1rem;
    gap: 1rem;
  }
  .entry-img {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .entry-img img {
    width: 100%;
    height: auto;
  }
  .entry-text {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .blog-title {
    width: 100%;
    margin-left: 0;
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .blog-entry {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    margin-left: 0;
    padding: 1rem;
  }
  .entry-title {
    margin-left: 0;
    margin-top: 1rem;
  }
  .entry-text {
    width: 100%;
    margin-left: 0;
  }
  .entry-img {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .entry-img img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .blog-title {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
  }
  .blog-entry {
    width: 100%;
    margin-left: 0;
    padding: 0.5rem;
    flex-direction: column-reverse;
  }
  .entry-title {
    margin-left: 0;
  }
  .entry-text {
    width: 100%;
    margin-left: 0;
  }
  .entry-date {
    margin-left: 0;
  }
  .entry-img {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .entry-img img {
    width: 100%;
    height: auto;
  }
}
</style>
