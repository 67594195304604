<template>
  <div id="app">
  <NavBar></NavBar>
  <router-view>
  </router-view>
  <MainFooter></MainFooter>
</div>
</template>

<script>
import NavBar from './components/NavBar/NavBar.vue';
import MainFooter from './components/MainFooter/MainFooter.vue';


export default {
  name: 'App',
  components: {
    NavBar,
    MainFooter
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
