<template>
  <BannerHome />
  <div v-for="[category, piercings] in piercingsByCategory" :key="category">
    <CarouselCards :category="category" :piercings="piercings" />
  </div>
  <TestimoniosHome />
  <ServiciosCards />
</template>

<script>
import BannerHome from "@/components/BannerHome/BannerHome.vue";
import CarouselCards from "@/components/CarouselCards/CarouselCards.vue";
import TestimoniosHome from "@/components/TestimoniosHome/TestimoniosHome.vue";
import ServiciosCards from "@/components/ServiciosCards/ServiciosCards.vue";

import { piercingServices } from "@/services/piercingServices";

export default {
  name: "HomeDesktop",
  components: {
    BannerHome,
    CarouselCards,
    TestimoniosHome,
    ServiciosCards,
  },
  data() {
    return {
      piercingsByCategory: new Map(),
      excludedSubcategories: ["Genitales", "Nipples"],
    };
  },
  computed: {},
  mounted() {
    this.listPiercings();
  },
  created() {},
  methods: {
    listPiercings(params) {
      piercingServices
        .list_piercings(params)
        .then((response) => {
          this.sortPiercingsByCategory(response.detail);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortPiercingsByCategory(piercings) {
      const piercingsByCategory = new Map();
      piercings.forEach((piercing) => {
        const category = piercing.category;
        const subcategory = piercing.subcategory;
        if (!this.excludedSubcategories.includes(subcategory)) {
          if (!piercingsByCategory.has(category)) {
            piercingsByCategory.set(category, []);
          }
          piercingsByCategory.get(category).push(piercing);
        }
      });

      this.piercingsByCategory = piercingsByCategory;
    },
  },
};
</script>

<style scoped>
* {
}
</style>
