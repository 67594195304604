import axios from "axios";
import baseAxios from "./baseServices";

const blogAxios = axios.create(baseAxios.defaults);

export const blogServices = {
    list_blogs: async(params) => {
        return blogAxios
        .get("blog-testimony/list/", {params})
        .then((response) =>response.data);
    },
    create_blog: async (formData, authToken) => {
        const config = {
          headers: {
            ...blogAxios.defaults.headers,
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
          },
        };
        return blogAxios
          .post("blog-testimony/create/", formData, config)
          .then(response => {
            return response;  // Return the full response here
          });
      },
    retrieve_blog: async (params) => {
        return blogAxios
          .post("blog-testimony/retrieve/", params)
          .then((response) => response.data)
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      },
    delete_blog: async(id, authToken) => {
        const config = {
            headers: {
                ...blogAxios.defaults.headers,
                Authorization: `Bearer ${authToken}`,
            },
        };
        return blogAxios
        .delete(`blog-testimony/delete/${id}/`, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    },

}