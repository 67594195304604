import axios from "axios";
import baseAxios from "./baseServices";

const piercingAxios = axios.create(baseAxios.defaults);

export const piercingServices = {
    list_piercings: async(params) => {
        return piercingAxios
        .get("piercing/list/", {params})
        .then((response) => {
            return response.data;
        })
        
    },
    create_piercing: async (formData, authToken) => {
        const config = {
            headers: {
                ...piercingAxios.defaults.headers,
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authToken}`,
            }
        };
    
        return piercingAxios
            .post("piercing/create/", formData, config)
            .then(response => {
                console.log("Received response:", response);
                return response;  // Ensure the full response is returned, not just response.data
            })
            .catch(error => {
                console.error("Error in create_piercing service:", error);
                if (error.response) {
                    console.error("Detailed error response:", error.response.data);
                }
                // Ensure to return a rejected promise in case of an error for consistent handling
                return Promise.reject(error.response || error);
            });
    },
    retrieve_piercing: async(id, ) => {
        const config = {
            headers: {
                ...piercingAxios.defaults.headers,
            },
        };
        return piercingAxios
        .get(`piercing/retrieve/${id}/`, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    },
    delete_piercing: async(id, authToken) => {
        const config = {
            headers: {
                ...piercingAxios.defaults.headers,
                Authorization: `Bearer ${authToken}`,
            },
        };
        return piercingAxios
        .delete(`piercing/delete/${id}/`, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    },
    categories_subcategories: async(message, detail) => {
        return piercingAxios
        .get(`piercing/category_subcategory/`, {message, detail})
        .then((response) => {
            return response.data;
        })
    }

}