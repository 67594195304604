import axios from "axios";
import baseAxios from "./baseServices";

const searchAxios = axios.create(baseAxios.defaults);

export const searchServices = {
  search: async (search) => {
    return searchAxios
      .get("search/", { params: { search } })
      .then((response) => response.data);
  },
};