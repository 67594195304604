<template>
  <div>
    <div class="blog-title">
      <div class="title-texts">
        <h1>Blog</h1>
        <div class="vertical-line"></div>
        <router-link to="/testimonios" style="text-decoration: none">
          <p>Testimonios</p>
        </router-link>
      </div>
      <div class="blog-button" v-if="isLogged">
        <router-link to="/blog_upload" style="text-decoration: none">
          <button>
            <h1>+ Subir entrada al blog</h1>
          </button>
        </router-link>
      </div>
    </div>
    <div class="blogs">
      <BlogCards :blogs="blogs"/>
    </div>
  </div>
</template>

<script>
import { blogServices } from '@/services/blogServices';
import BlogCards from '@/components/BlogCard/BlogCards.vue';


export default {
  name: "BlogView",
  components: {
    BlogCards,
  },
  props: {},
  mounted: function () {
    this.retrieveBlog();
  },
  computed: {
    isLogged: function () {
      if (this.$store.state.accessToken !== null) {
        return true;
      } else {
        return false;
      }
    },
    listBlogs() {
      return this.blogs;
    }
  },
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    retrieveBlog() {
    const params = { id: this.$route.params.id };
    blogServices.list_blogs(params)
      .then((response) => {
        console.log("Blogs retrieved:", response.data); // Log the full response data
        // Filter out only the items with type 'blog'
        this.blogs = response.data.filter(item => item.type === 'blog');
        console.log("Filtered Blogs:", this.blogs); // Log the filtered blog data
      })
      .catch((error) => {
        console.error("Error retrieving blogs:", error);
      });
  },
  },
};
</script>


<style scoped>
.vertical-line {
  width: 0.09375rem;
  height: 1.6875rem;
  background: #9e7338;
}
.blog-title {
  display: flex;
  width: 81.5rem;
  padding: 1.1875rem 0rem;
  justify-content: space-between;
  align-items: center;
  margin-left: 6.5rem;
}
.title-texts {
  display: flex;
  align-items: center;
  gap: 1.125rem;
}
.title-texts h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
}
.title-texts p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.03375rem;
  transition: all 0.3s ease-in-out;
}
.title-texts p:hover {
  color: var(--primary-gold, #9e7338);
  transition: all 0.3s ease-in-out;
}
.blog-button button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-primary, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}
.blog-button button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.search-button button {
  display: flex;
  width: 12.4375rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  cursor: pointer;
}
.search-button button h1 {
  color: var(--black-black-60, #6a6a6d);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.blogs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin: auto;
}
@media screen and (max-width: 1440px) {
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 0;
    padding: 1rem;
  }
  .blogs {
    width: 95%;
    justify-content: center;
  }
  .search-button {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    gap: 1.5rem;
  }
  .blogs {
    width: 95%;
    justify-content: center;
  }
  .search-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    gap: 1.5rem;
  }
  .blogs {
    width: 90%;
    justify-content: center;
  }
  .search-button {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    gap: 1.5rem;
  }
  .blogs {
    width: 90%;
    justify-content: center;
  }
  .search-button {
    display: none;
  }
}
</style>
