import axios from "axios";
import baseAxios from "./baseServices";

const contactAxios = axios.create(baseAxios.defaults);

export const contactServices = {
    create: async(data) => {
        return contactAxios
        .post("contact/create/", data)
        .then((response) => response.data);
    }
}