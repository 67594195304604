<template>
    <div v-if="isOpen" class="dropmenu">
        <div class="dropdown-header">
            <div class="logo">
                <router-link to="/" @click="closeDropdown"
            ><img src="@/assets/img/Logo-mobile.png" alt="Logo FloGuzman"
          /></router-link>
        </div>
        <div>
            <div>
                <button @click="closeDropdown">
                    <img src="@/assets/icons/close.svg" alt="Cerrar" />
                </button>
            </div>
        </div>
        </div>
        <div class="col-10">
                <div class="menu-items" >
                <router-link to="/portafolio" style="text-decoration: none;" @click="closeDropdown">
                    <h3>Portafolio</h3>
                </router-link>
                </div>
            </div>
            <div class="col-10">
                <div class="menu-items" >
                <router-link to="/cuidados" style="text-decoration: none;" @click="closeDropdown">
                    <h3>Cuidados · Preguntas</h3>
                </router-link>
                </div>
            </div>
            <div class="col-10">
                <div class="menu-items" >
                    <router-link to="/blog" style="text-decoration: none" @click="closeDropdown">
            <h3>Blog</h3>
          </router-link>
                </div>
            </div>
            <div class="col-10">
                <div class="menu-items" >
                <router-link to="/about" style="text-decoration: none;" @click="closeDropdown">
                    <h3>Sobre mi</h3>
                </router-link>
                </div>
            </div>
            <div class="col-10">
                <div class="menu-items" >
                <router-link to="/contacto" style="text-decoration: none;" @click="closeDropdown">
                    <h3>Contacto</h3>
                </router-link>
                </div>
            </div>
            <div class="col-50">
                <div class="buttons-reservas" >
                <a href="https://linktr.ee/Flopiercer" style="text-decoration: none;">
                    <button type="submit">
                    Reserva tu hora
                </button>
                </a>
                </div>
            </div>
    </div>
</template>

<script>
export default {
  name: 'ResponsiveMenu',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDropdown() {
      this.$emit('close');
    },
  },
}
</script>

<style src="./ResponsiveMenu.css">

</style>