<template>
    <div>
        <LoginForm/>
    </div>

</template>

<script>
import LoginForm from '@/components/LoginForm/LoginForm.vue';

export default {
  name: 'LoginView',
  components: {
    LoginForm,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>

<style scoped>
</style>