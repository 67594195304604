<template>
  <div>
    <div class="slider-title">
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      </div>
      <div class="slider-titles">
        <h1>Portafolio</h1>
      </div>
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      </div>
    </div>
    <div class="portafolio-subtitle">
      <router-link to="/portafolio" style="text-decoration: none">
        <h1>Perforaciones</h1>
      </router-link>
      <h2 v-if="piercing && piercing.category">·</h2>
      <router-link
        :to="'/portafolio/' + (piercing ? piercing.category.toLowerCase() : '')"
        style="text-decoration: none"
      >
        <h1>{{ piercing ? piercing.category : "" }}</h1>
      </router-link>
      <h2 v-if="piercing && piercing.subcategory">·</h2>
      <p>{{ piercing ? piercing.subcategory : "" }}</p>
    </div>
    <div id="gallery" ref="gallery" class="gallery">
      <ul class="images">
        <li
          v-for="piercing in filteredPiercings"
          :key="piercing.id"
        >
          <a :href="piercing.photo_url" :data-src="piercing.photo_url" @click.prevent="openFullscreen">
            <img :src="piercing.photo_url" :alt="piercing.title_description" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { piercingServices } from "@/services/piercingServices";

export default {
  name: "PortafolioGallery",
  components: {},
  data() {
    return {
      piercingsBySubcategory: [],
      piercing: null,
      options: {
        inline: false,
        oneToOne: false,
        initialViewIndex: 0,
        play: false,
        keyboard: false,
        loop: true,
        zoom: true,
        rotatable: false,
        scalable: false,
        movable: false,
        loading: true,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 0,
          reset: 0,
          prev: 1,
          play: {
            show: 0,
            size: "large",
          },
          next: 1,
          rotateLeft: 0,
          rotateRight: 0,
          flipHorizontal: 0,
          flipVertical: 0,
        },

      },
      viewer: null,
      
    };
  },
  computed: {
    filteredPiercings() {
      return this.piercingsBySubcategory;
    },
  },
  mounted() {
    this.subcategory = this.$route.params.subcategory;
    this.fetchPiercings();
  },
  methods: {
    async fetchPiercings() {
      try {
        const subcategory = this.$route.params.subcategory.toLowerCase();
        const response = await piercingServices.list_piercings();
        if (response.detail && Array.isArray(response.detail)) {
          const piercingsData = response.detail.filter(
            (item) => item.subcategory.toLowerCase() === subcategory
          );
          this.piercingsBySubcategory = piercingsData;
          this.piercing = piercingsData.length > 0 ? piercingsData[0] : null;
          this.initializeViewer();
        } else {
          console.error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching piercings:", error);
      }
    },
    initializeViewer() {
      // Initialize Viewer after the data is fetched and DOM is ready
      this.$nextTick(() => {
        // Access the DOM element using $refs
        const galleryElement = this.$refs.gallery;
        // Create Viewer instance
        this.viewer = new Viewer(galleryElement, this.options);
      });
    },
    openFullscreen(event) {
      event.preventDefault(); // Prevent the default behavior of anchor tag
      // You can add additional logic here to open fullscreen mode
    },
  },
};
</script>

<style scoped>
.gallery{
  width: 87%;
  margin: auto;
  padding: 2rem 0rem;
 
}
.images{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 280px;
  grid-auto-flow: dense;
   gap: 0.5rem;
}
.images > a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.images  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.portafolio-subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 0rem 0rem 0rem 7.5rem;
}
.portafolio-subtitle h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: Playfair 9pt;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle h2 {
  color: var(--primary-primary, #9e7338);
  font-family: Playfair 9pt;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle p {
  font-family: Playfair 9pt;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
  background: var(
    --primary-gold,
    linear-gradient(180deg, #9e7338 0%, #875421 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 26.875rem) {
  .portafolio-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0rem;
    gap: 0.5rem;
    margin: 0rem 0rem 0rem 2rem;
  }
  .portafolio-subtitle h1 {
    color: var(--black-black-40, #9b9b9e);
    font-family: "Playfair 9pt";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 1.4875rem */
  }
  .images {
    width: 100%;
    margin: auto;
    padding: 2rem 0rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}
</style>
