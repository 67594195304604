import { createApp } from 'vue'
import vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'


import './assets/css/App.css';
import './assets/sass/style.css';

const app = createApp(App)
app.use(vuex)
app.use(store)

app.use(router)


app.mount('#app')
