<template>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <p>Sobre mi</p>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div class="about-first-section">
    <div class="first-section-texts">
      <div class="first-section-title">
        <h1>Florencia Guzmán Irrazábal</h1>
      </div>
      <div class="first-section-paragraphs">
        <div class="first-paragraph">
          <p>
            Me llamo Florencia Guzmán Irrazábal, llevo 8 años dedicándome al
            rubro del body piercing y hace más de 3 años que decidí establecerme
            profesionalmente en este campo que tanto me apasiona. Cada
            perforación que realizo, ya sea en orejas, faciales o corporales, es
            una muestra de mi dedicación y amor por este oficio.
          </p>
          <br>
        </div>
        <div class="first-paragraph">
          <p>
            Trabajo en un estudio reconocido donde
            <u
              >me enfoco en mantener los más altos estándares de limpieza,
              esterilización y cuidado</u
            >
            para una fructífera perforación. Para mí, la excelencia en mi
            trabajo va de la mano con la seguridad y el bienestar de mis
            clientes.
          </p>
          <br>
        </div>
        <div class="first-paragraph">
          <p>
            No solo me preocupo por la técnica de la perforación, sino también
            por brindar una atención integral. <u
              >Es fundamental para mí educar a
            mis clientes sobre los cuidados necesarios para mantener una
            perforación sana y exitosa</u
            >. Siempre estoy dispuesta a explicar
            detalladamente los pasos a seguir y a responder todas las preguntas
            y preocupaciones que puedan tener antes y después de una
            perforación.
          </p>
          <br>
        </div>
        <div class="first-paragraph">
          <p>
            Mi ética profesional y mi compromiso con la seguridad es
            inquebrantable. Me aseguro de mantener un entorno seguro y libre de
            riesgos mediante prácticas rigurosas de limpieza y esterilización.
            Creo que la confianza y la tranquilidad de mis clientes son
            fundamentales, por eso me esfuerzo por crear un ambiente acogedor y
            transmitirles seguridad en cada sesión.
          </p>
        </div>
      </div>
    </div>
    <div class="about-first-image">
      <img src="@/assets/img/flo-retrato.png" alt="" />
    </div>
  </div>
  <div class="about-second-section">
    <div class="about-second-image">
      <img src="@/assets/img/sobremi1.jpg" alt="" />
    </div>
    <div class="second-section-texts">
      <div class="first-section-title">
        <h1>Mis Valores</h1>
      </div>
      <div class="first-section-paragraphs">
        <div class="second-paragraph">
          <p>
            <u>Exclusividad:</u> Nuestro enfoque se centra en brindar a cada
            cliente un servicio personalizado que se adapta completamente a sus
            preferencias y necesidades. Como expertos, estamos aquí para
            asesorarles en la elección de la ubicación, diseño y tipo de joyería
            más adecuados, siempre buscando opciones únicas y de la más alta
            calidad.
          </p>
          <br>
        </div>
        <div class="second-paragraph">
          <p>
            <u>Profesionalismo:</u> Como miembros altamente capacitados, nuestra
            prioridad es asegurar que su experiencia sea segura y exitosa.
            Cumplimos rigurosamente con todas las normas de seguridad e higiene,
            y nuestro trato siempre será amable y respetuoso, reflejando una
            ética profesional sólida.
          </p>
          <br>
        </div>
        <div class="second-paragraph">
          <p>
            <u>Limpieza:</u> La higiene y desinfección son aspectos esenciales
            en todas las áreas de nuestro estudio. Utilizamos equipo
            esterilizado y desechable, y seguimos estrictos protocolos de
            bioseguridad para garantizar un ambiente seguro y limpio tanto para
            usted como para nosotros.
          </p>
          <br>
        </div>
        <div class="second-paragraph">
          <p>
            <u>Inclusividad:</u> Nos comprometemos firmemente a crear un
            ambiente acogedor y respetuoso para todas las personas, sin importar
            su género, raza, orientación sexual o cualquier otra característica.
            Fomentamos la diversidad y nos aseguramos de que todos nuestros
            clientes se sientan bienvenidos y cómodos durante su visita.
          </p>
          <br>
        </div>
        <div class="second-paragraph">
          <p>
            <u>Cercanía:</u> Valoramos la conexión emocional con nuestros
            clientes y deseamos que se sientan cómodos y relajados en nuestro
            estudio. Estamos aquí para proporcionarles información detallada,
            responder a sus preguntas y demostrar empatía en todo momento,
            asegurándonos de que tengan una experiencia positiva y satisfactoria
            con nosotros.
          </p>
        </div>
      </div>
    </div>
  </div>
  <ServiciosCards />
</template>

<script>
import ServiciosCards from "@/components/ServiciosCards/ServiciosCards.vue";
export default {
  name: "AboutView",
  components: {
    ServiciosCards,
  },
};
</script>

<style scoped>
.about-first-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 10rem;
  width: 100%;
}
.first-section-texts {
  width: 32.25rem;
  height: 31.6875rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.first-section-title {
  display: flex;
  justify-content: flex-start;
}
.first-section-title h1 {
  color: var(--primary-primary-light, #cfa869);
  text-align: right;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.05625rem;
}
.first-section-paragraphs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.first-paragraph {
  display: flex;
  flex-direction: column;
  text-align: justify;
  height: 100%;
}
.first-paragraph p {
  color: var(--black-black-20, #cdcdce);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 170%; /* 1.4875rem */
}
.first-paragraph u {
  text-decoration: none;
  color: var(--black-black-20, #cdcdce);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}
.about-first-image {
  width: 39.25rem;
  height: 31.6875rem;
}
.about-first-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1.1) saturate(0.9) contrast(1);
  overflow: hidden;
}
.about-second-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 10rem;
  width: 100%;
}
.second-section-texts {
  width: 32.25rem;
  height: 37.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.second-paragraph {
  display: flex;
  flex-direction: column;
  text-align: justify;
  height: 100%;
}
.second-paragraph p {
  color: var(--black-black-20, #cdcdce);
  text-align: justify;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 170%; /* 1.4875rem */
}
.second-paragraph u {
  text-decoration: none;
  color: var(--primary-primary, #9e7338);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
}
.about-second-image {
  width: 39.25rem;
  height: 37.5rem;
}
.about-second-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1) saturate(0.9) contrast(1);
  overflow: hidden;
}
@media screen and (max-width: 26.875rem) {
  .about-first-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .first-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    gap: 1rem;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .first-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .first-paragraph u {
    text-decoration: none;
    color: var(--black-black-20, #cdcdce);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-first-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .second-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .second-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .second-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .second-paragraph u {
    text-decoration: none;
    color: var(--primary-primary, #9e7338);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width: 20rem) {
  .about-first-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 0rem;
  }
  .first-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    gap: 1rem;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .first-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .first-paragraph u {
    text-decoration: none;
    color: var(--black-black-20, #cdcdce);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-first-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .second-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .second-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .second-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .second-paragraph u {
    text-decoration: none;
    color: var(--primary-primary, #9e7338);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width: 1024px) {
  .about-first-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .first-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    gap: 1rem;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .first-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .first-paragraph u {
    text-decoration: none;
    color: var(--black-black-20, #cdcdce);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-first-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .second-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .second-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .second-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .second-paragraph u {
    text-decoration: none;
    color: var(--primary-primary, #9e7338);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .about-first-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .first-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .first-section-title {
    display: flex;
    justify-content: center;
  }
  .first-section-title h1 {
    color: var(--primary-primary-light, #cfa869);
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 1.4625rem */
    letter-spacing: 0.05625rem;
  }
  .first-section-paragraphs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    gap: 1rem;
  }
  .first-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .first-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .first-paragraph u {
    text-decoration: none;
    color: var(--black-black-20, #cdcdce);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }
  .about-first-image {
    width: 100%;
    height: 100%;
  }
  .about-first-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1.1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
  .about-second-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .second-section-texts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .second-paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100%;
  }
  .second-paragraph p {
    color: var(--black-black-20, #cdcdce);
    text-align: justify;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 1.4875rem */
  }
  .second-paragraph u {
    text-decoration: none;
    color: var(--primary-primary, #9e7338);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
  }
  .about-second-image {
    width: 100%;
    height: 100%;
  }
  .about-second-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1) saturate(0.9) contrast(1);
    overflow: hidden;
  }
}
</style>
