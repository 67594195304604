<template>
  <div class="Testimonios-card" :style="{ width: cardWidth + 'px' }">
    <div class="Testimonios-card-titles">
      <h1>{{ testimonio.name }}</h1>
      <p>{{ testimonio.profession }}, {{ testimonio.who }}</p>
    </div>
    <div class="Testimonios-card-content">
      <p ref="description">{{ testimonio.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonio: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardWidth: 300, // Default card width
    };
  },
  mounted() {
    this.adjustCardWidth();
  },
  methods: {
    adjustCardWidth() {
      this.$nextTick(() => {
        const descriptionElement = this.$refs.description;
        if (descriptionElement) {
          const textLength = descriptionElement.textContent.length;
          let tempWidth = 200;

          if (textLength > 100) {
            if (textLength <= 100) {
              tempWidth = 200;
            } else if (textLength <= 200) {
              tempWidth = 300;
            } else if (textLength <= 300) {
              tempWidth = 400;
            } else if (textLength <= 400) {
              tempWidth = 500;
            } else if (textLength <= 500) {
              tempWidth = 600;
            } else if (textLength <= 6000) {
              tempWidth = 700;
            } else if (textLength <= 700) {
              tempWidth = 800;
            } else if (textLength <= 800) {
              tempWidth = 900;
            } else {
              tempWidth = 1000;
            }
          }

          this.cardWidth = tempWidth;
        }
      });
    },
  },
  watch: {
    testimonio: {
      handler() {
        this.adjustCardWidth();
      },
      deep: true,
    },
  },
};
</script>

<style src="./TestimoniosCard.css"></style>
