<template>
  <div class="blog-uploader">
    <div class="blog-title">
      <div class="title-texts">
        <h1>Blog</h1>
        <div class="vertical-line"></div>
        <router-link to="/testimonios" style="text-decoration: none">
          <p>Testimonios</p>
        </router-link>
      </div>
      <div class="blog-titles">
        <h1>Blog</h1>
        <h2>·</h2>
        <p>Subir una entrada</p>
      </div>
    </div>
    <div class="upload-box">
      <div class="uploader">
        <div class="form-A">
          <form @submit.prevent="upload">
            <div class="form-title">
              <h1>Titulo</h1>
              <input type="text" placeholder="Título de entrada del blog" v-model="title"/>
            </div>
            <div class="form-message">
              <h1>Mensaje</h1>
              <quill-editor 
                ref="quillEditor"
                v-model:content="description"
                :options="editorOptions"
                theme="snow"  
                class="custom-quill-editor"
                placeholder="Mensaje de una entrada del blog"
              />
            </div>
            <div class="form-subtitle">
              <h1>Hipervinculo</h1>
              <input type="text" placeholder="www.nombredominio.com" v-model="hyperlink"/>
            </div>
            <div class="form-subtitle">
              <h1>Etiquetas</h1>
              <input type="text" v-model="labels"/>
            </div>
            <div class="submit-buttons">
              <button type="button" @click="resetForm"><h1>Cancelar</h1></button>
              <div v-if="isLogged">
                <button class="submit-button" type="submit" >
                  <h1>Publicar entrada</h1>
                </button>
              </div>
              <div v-else>
                <button class="submit-button" type="button">
                  <h1>Publicar entrada</h1>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="picture-upload">
          <div class="picture-title">
            <h1>Imagen</h1>
          </div>
          <div
            class="dropzone"
            @dragover.prevent
            @dragenter.prevent
            @dragstart.prevent
            @drop.prevent="handleFileChange($event.dataTransfer)"
          >
            <input
              id="file-input"
              type="file"
              accept="image/png, image/jpeg"
              @change="handleFileChange($event.target)"
              required
            />
            <img v-if="preview" :src="preview" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blogServices } from "@/services/blogServices";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill';  // Import Quill

export default {
  name: "BlogUpload",
  components: {
    QuillEditor
  },
  data() {
    return {
      title: "",
      description: "", // This will hold the Delta object
      hyperlink: "",
      labels: "",
      file: null,
      preview: null,
      preset: process.env.VUE_APP_UPLOAD_PRESET,
      cloudName: process.env.VUE_APP_CLOUD_NAME,
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            [ 'font'  ],
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] } ],
          ]
        }
      }
    };
  },
  computed: {
    isLogged() {
      return !!this.$store.state.accessToken;
    },
  },
  methods: {
    handleFileChange(event) {
      const fileList = event.files || event.target.files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      this.file = file;

      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("file", file);

      const reader = new FileReader();
      reader.onload = () => {
        this.preview = reader.result;
      };
      reader.readAsDataURL(file);
    },
    extractPlainTextFromDelta(delta) {
      return delta.ops.map(op => op.insert).join('');
    },
    extractHTMLFromDelta(delta) {
      const quill = new Quill(document.createElement('div'));  // Create a temporary Quill instance
      quill.setContents(delta);  // Set the contents using the Delta object
      return quill.root.innerHTML;  // Extract the HTML from the Quill instance
    },
    upload() {
      const authToken = this.$store.state.accessToken;
      if (!authToken) {
        console.error("Authentication token not found.");
        alert("Authentication token not found. Please log in again.");
        return;
      }

      // Extract the HTML from the Delta object
      const extractedHTML = this.extractHTMLFromDelta(this.description);

      // Use FormData when uploading files
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("description", extractedHTML);  // Use the extracted HTML
      formData.append("type", "blog");

      if (this.file) {
        formData.append("blog_photo", this.file);
      }

      blogServices.create_blog(formData, authToken)
        .then(response => {
          console.log("HTTP Response:", response);  // Log the full HTTP response
          console.log("Blog entry uploaded successfully:", response.data);  // Log the response data, if any
          this.resetForm();
        })
        .catch(error => {
          console.error("Error uploading blog entry:", error);
        });
    },
    resetForm() {
      this.title = "";
      this.description = "";
      this.hyperlink = "";
      this.labels = "";
      this.file = null;
      this.preview = null;
      this.formData = null;
    },
  }
};
</script>


<style scoped>
.title-texts {
  display: none;
}
.title-texts h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
}
.title-texts p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.03375rem;
  transition: all 0.3s ease-in-out;
}
.blog-titles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 1.5rem 0rem 0rem 7.5rem;
}
.blog-titles h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
}
.blog-titles h2 {
  color: var(--primary-primary, #9e7338);
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4875rem */
}
.blog-titles p {
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
  background: var(
    --primary-gold,
    linear-gradient(180deg, #9e7338 0%, #875421 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 81.5rem;
  flex-shrink: 0;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 0rem 0rem 4rem 7.5rem;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
.uploader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  height: 100%;
}
.form-A {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.form-A h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 0.4rem;
}

.form-A p {
  color: var(--Black-600, #717d96);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: -0.00875rem;
}
.form-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-title input {
  width: 40.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-subtitle input {
  width: 40.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
  margin-bottom: 1rem;
}
.form-message textarea {
  width: 40.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  resize: none;
  outline: none;
}
.picture-upload {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  padding: 1.1875rem 0rem;
  margin: 0rem 0rem 0rem 5rem;
}
.picture-upload h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 0.6rem;
}
.dropzone {
  width: 23.25rem;
  height: 33.25rem;
  border-radius: 0.0625rem;
  border: 1px solid var(--black-black-80, #38383d);
  background: #06060c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  height: inherit;
  cursor: pointer;
}
img {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}
.submit-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 2rem;
  gap: 1rem;
  width: 91%;
  height: 100%;
}
.submit-buttons button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--black-black-40, #9b9b9e);
  background: var(--black-black, #06060c);
}
.submit-buttons button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.submit-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
@media screen and (max-width: 26.875rem) {
  .vertical-line {
    width: 0.09375rem;
    height: 1.6875rem;
    background: #9e7338;
  }
  .title-texts {
    display: flex;
    align-items: center;
    gap: 1.125rem;
  }
  .blog-title {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    margin: 0rem 0rem 0rem 1rem;
    gap: 1.5rem;
  }
  .blog-titles {
    display: flex;
    align-items: flex-start;
    margin: 0rem 0rem 0rem 0rem;
    gap: 1.5rem;
  }
  .search-button {
    display: none;
  }
  .upload-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-shrink: 0;
    padding: 1.1875rem 0rem;
    gap: 0.4rem;
    margin: 0rem 0rem 0rem 1rem;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
  }
  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    height: 100%;
    width: 100%;
  }
  .form-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-A h1 {
    color: var(--black-black-40, #9b9b9e);
    font-feature-settings: "calt" off;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 133.333% */
    letter-spacing: -0.0075rem;
    margin-bottom: 0.4rem;
  }
  .form-A p {
    color: var(--Black-600, #717d96);
    font-feature-settings: "calt" off;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1rem; /* 114.286% */
    letter-spacing: -0.00875rem;
  }
  .form-A input {
    width: 17rem;
  }
  .form-A textarea{
    width: 17rem;
  }
  .picture-upload{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin: 0rem 0rem 0rem 0rem;
  }
.picture-title{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2.5rem;
}
  .dropzone{
    width: 80%;
    height: 24rem;
    border-radius: 0.0625rem;
    border: 1px solid var(--black-black-80, #38383d);
    background: #06060c;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  height: inherit;
  cursor: pointer;  
}
.submit-buttons{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  width: 80%;
  height: 80%;
}
img {  
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
}
}
</style>
