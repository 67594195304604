import axios from "axios";
import baseAxios from "./baseServices";

const faqAxios = axios.create(baseAxios.defaults);

export const faqServices = {
    list: async(params) => {
        return faqAxios
        .get("faq/list/", {params})
        .then((response) => response.data);
    },
    
    create: async(params, authToken) => {
        const config = {
            headers: {
                ...faqAxios.defaults.headers,
                Autorization: `Bearer ${authToken}`,
            },
        };
        return faqAxios
        .post("faq/create/", params, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })

    },
    retrieve: async(id) => {
        return faqAxios
        .get(`faq/retrieve/${id}/`)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    },
    patch: async(id, params, authToken) => {
        const config = {
            headers: {
                ...faqAxios.defaults.headers,
                Autorization: `Bearer ${authToken}`,
            },
        };
        return faqAxios
        .patch(`faq/update/${id}/`, params, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    },
    delete: async(id, authToken) => {
        const config = {
            headers: {
                ...faqAxios.defaults.headers,
                Autorization: `Bearer ${authToken}`,
            },
        };
        return faqAxios
        .delete(`faq/delete/${id}/`, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
    }
}