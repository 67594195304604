import axios from "axios";
import baseAxios from "./baseServices";

const loginAxios = axios.create(baseAxios.defaults);

export const loginServices = {
    login: async (username, password) => {
      return loginAxios
        .post("/login/token/", {
          username: username,
          password: password,
        })
        .then((response) => response.data.access);
    },
};