<template>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <p>Perforaciones</p>
      <h1>{{ formatSubcategory(category) }}</h1>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div class="swiper-container">
    <swiper
      class="ear-swiper"
      :breakpoints="breakpoints"
      :centeredSlides="false"
      :navigation="true"
      :modules="modules"
      :spaceBetween="5"
    >
      <swiper-slide
        class="ear-slide"
        v-for="piercing in limitedPiercings"
        :key="piercing.id"
      >
        <div class="img" @mouseenter="function1(piercing.id)" @mouseleave="function2">
          <img
            :src="getImageUrl(piercing)"
            :alt="piercing.title_description"
          />
          <div class="blurry-padding" v-if="isHovered === piercing.id">
            <div class="slides-text">
              <p>{{ piercing.title_description }}</p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";


export default {
  name: "CarouselCards",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    category: String, 
    piercings: Array, 
  },
  setup() {
    return {
      modules: [Navigation],
      breakpoints: {
        1250: {
          slidesPerView: 3.5,
          slidesPerGroup: 3,
        },
        1080: {
          slidesPerView: 3,
          slidesPerGroup: 2,
        },
        769: {
          slidesPerView: 2.5,
          slidesPerGroup: 2,
        },
        481: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    };
  },
  data() {
    return {
      isHovered: null, 
    };
  },
  computed: {
    limitedPiercings() {
      // Shuffle function
      const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      };

      const shuffledPiercings = shuffleArray(this.piercings.slice());
      return shuffledPiercings.slice(0, 10);
    },

  },
  mounted() {
  },
  methods: {
    function1(id) {
      this.isHovered = id;
    },
    function2() {
      this.isHovered = null;
    },
    getImageUrl(piercing) {
  return piercing ? piercing.photo_url : "";
},
formatSubcategory(category) {
  if (category.toLowerCase() === "orejas") {
    const formattedCategory = "de " + category;
    return formattedCategory;
  } else {
    return category;
  }
},
  },
};
</script>

<style src="./CarouselCards.css"></style>
