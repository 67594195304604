<template>
  <div>
    <div v-for="blog in blogs" :key="blog.id" class="blog-entry">
      <div>
        <div class="entry-title">
          <!-- Correctly binding the router-link's 'to' attribute -->
          <router-link :to="`/blog/${blog.id}`" style="text-decoration: none">
            <h1>{{ blog.title }}</h1>
          </router-link>
        </div>
        <div class="entry-text">
          <!-- Use v-html to render HTML content properly -->
          <p v-html="blog.description"></p>
        </div>
        <div class="entry-date">
          <h1>Publicado el {{ formatDate(blog.created_at) }}</h1>
        </div>
      </div>
      <div class="entry-img">
        <router-link :to="`/blog/${blog.id}`" style="text-decoration: none">
          <!-- Display the image using the signed_url or photo_url -->
          <img :src="getImageUrl(blog)" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCards",
  props: {
    blogs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    getImageUrl(blog) {
  console.log('Image URL being used:', blog.signed_url);
  return blog.signed_url || "";
}
  },
};
</script>

<style scoped>
.blog-entry {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 81.5rem;
  height: 30.625rem;
  flex-shrink: 0;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: rgba(29, 29, 36, 0.5);
  margin-left: 6.5rem;
  margin-bottom: 2rem;
}
.entry-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 2rem;
  margin-left: 1.25rem;
  margin-bottom: 2rem;
}
.entry-title h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.625rem */
  transition: all 0.3s ease-in-out;
}
.entry-title h1:hover {
  color: var(--primary-gold, #9e7338);
  transition: all 0.3s ease-in-out;
}
.entry-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 13; /* Number of lines after which the text is truncated */
  overflow: hidden;
  gap: 0.625rem;
  width: 38.125rem;
  flex-shrink: 0;
  margin-left: 1.25rem;
  text-overflow: ellipsis;
  text-align: justify;
}
.entry-text p {
  color: var(--black-black-20, #cdcdce);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}
.entry-date {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.625rem;
  margin-top: 3rem;
  margin-left: 1.25rem;
}
.entry-date h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.05625rem */
}
.entry-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.entry-img img {
  width: 35.0625rem;
  height: 30.625rem;
  object-fit: cover;
  border: 1px solid var(--black-black-80, #38383d);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .blog-entry {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 2rem;
    padding: 0;
  }
  .entry-text {
    width: 90%;
    margin-left: 0;
  }
  .entry-img {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .entry-img img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1440px) {
  .blog-entry {
    width: 95%;
    height: auto;
    margin-left: 0;
  }
  .entry-text {
    width: 90%;
  }
  .entry-img img {
    width: 100%;
    height: auto;
  }
}

</style>
