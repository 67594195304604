<template>
  <div>
    <div class="searchtitle">
      <img src="@/assets/icons/lupa.svg" alt="" />
      <p>Resultados de la búsqueda</p>
    </div>

    <!-- Portafolio section -->
    <div class="searchporta">
      <p>Portafolio</p>
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      </div>
    </div>
    <div class="results">
      <div v-if="results && results.piercing && results.piercing.length > 0">
        <div class="result">
          <p>Resultados para</p>
          <u>{{ $route.query.q }}</u>
          <p>en</p>
          <u>Portafolio</u>
        </div>
        <div class="result" v-for="result in results.piercing" :key="result.id">
          <div class="image_results">
            <img :src="result.photo_url" alt="Image" class="search-image" />
          </div>
        </div>
      </div>
      <div class="result" v-else>
        <p>No se encontraron resultados para</p>
        <u>{{ $route.query.q }}</u>
        <p>en</p>
        <u>Portafolio</u>
      </div>
    </div>

    <!-- Cuidados/Preguntas section -->
    <div class="searchcuidados">
      <div class="cuidadossubs">
        <p>Cuidados</p>
        <p>·</p>
        <p>Preguntas</p>
      </div>
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      </div>
    </div>
    <div class="results">
      <div v-if="results && results.faq && results.faq.length > 0">
        <div class="result">
          <p>Resultados para</p>
          <u>{{ $route.query.q }}</u>
          <p>en</p>
          <u>Cuidados · Preguntas</u>
        </div>
        <div class="result" v-for="result in results.faq" :key="result.id">
          <div class="faq_res">
            <h1>{{ result.question }}</h1>
            <p>{{ result.answer }}</p>
            <!-- Display other properties of the result as needed -->
          </div>
        </div>
      </div>
      <div class="result" v-else>
        <p>No se encontraron resultados para</p>
        <u>{{ $route.query.q }}</u>
        <p>en</p>
        <u>Cuidados · Preguntas</u>
      </div>
    </div>

    <!-- Blog/Testimonio section -->
    <div class="searchblog">
      <div class="blogsubs">
        <p>Blog</p>
        <p>·</p>
        <p>Testimonios</p>
      </div>
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      </div>
    </div>
    <div class="results">
      <div
        v-if="
          results &&
          results.blog_or_testimony &&
          results.blog_or_testimony.length > 0
        "
      >
        <div class="result">
          <p>Resultados para</p>
          <u>{{ $route.query.q }}</u>
          <p>en</p>
          <u>Blog · Testimonios</u>
        </div>
        <div
          class="result"
          v-for="result in results.blog_or_testimony"
          :key="result.id"
        >
        <div class="blog_res">
          <p>{{ result.type }}</p>
          <p>{{ result.title}}</p>
          <p>{{ result.description }}</p>
          <!-- Display other properties of the result as needed -->
        </div>
          
        </div>
      </div>
      <div class="result" v-else>
        <p>No se encontraron resultados para</p>
        <u>{{ $route.query.q }}</u>
        <p>en</p>
        <u>Blog · Testimonios</u>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusquedaView",
  components: {},
  data() {
    return {
      results: null,
    };
  },
  methods: {},
  computed: {},
  mounted() {
    // Fetch search results based on $route.query.q
    const query = this.$route.query.q;
    console.log("Query:", query);

    // Parse search results from route query parameters
    if (this.$route.query.results) {
      this.results = JSON.parse(this.$route.query.results);
      console.log("Search results:", this.results);
    }
  },
};
</script>

<style>
.searchtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchtitle p {
  font: 275 20px/24px "Poppins";
  color: rgba(231, 231, 231, 1);
}
.searchporta {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchporta p {
  font: 300 20px/24px "Playfair 9pt";
  color: rgba(158, 115, 56, 1);
}
.searchcuidados {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cuidadossubs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cuidadossubs p {
  font: 300 20px/24px "Playfair 9pt";
  color: rgba(158, 115, 56, 1);
  margin: auto 3px;
}
.searchblog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogsubs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogsubs p {
  font: 300 20px/24px "Playfair 9pt", sans-serif;
  color: rgba(158, 115, 56, 1);
  margin: auto 3px;
}
.results {
  display: flex;
  align-items: center;
  justify-content: center;
}
.results p {
  font: 300 20px/24px "Poppins", sans-serif;
  color: rgba(231, 231, 231, 1);
  margin: auto 3px;
}
.results u {
  font: 600 20px/24px "Poppins", sans-serif;
  color: rgba(231, 231, 231, 1);
  margin: auto 3px;
  text-decoration: none;
}
.result {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.dots-line {
  height: 0.125rem;
  width: auto 100%;
  background-color: rgba(158, 115, 56, 1);
}
.search-image {
  width: 323px;
  height: 311px;
  object-fit: cover;
  border: 2px, 0px, 2px, 2px;
  margin: 10px;
}
.faq_res{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0px 10px 0px 10px;
  padding: 5px 5px 5px 5px;
  width:  1380px;
  border: 1px solid rgba(56, 56, 61, 1);
}
.faq_res h1 {
  font: 500 20px/24px "Poppins", sans-serif;
  color:rgba(158, 115, 56, 1);
}
.blog_res{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin: 0px 10px 0px 10px;
  padding: 5px 5px 5px 5px;
  width:  1380px;
  border: 1px solid rgba(56, 56, 61, 1);
}
.image_results{
  display: flex;
  flex-direction: row 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1px 1px;
}
</style>
