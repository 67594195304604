<template>
  <div class="testimonio-upload">
    <div class="title-texts">
      <h1>Blog</h1>
      <div class="vertical-line"></div>
      <router-link to="/testimonios" style="text-decoration: none">
        <p>Testimonios</p>
      </router-link>
    </div>
    <div class="testimonio-title">
      <h1>Testimonio</h1>
      <h2>·</h2>
      <p>Subir un testimonio</p>
    </div>
    <div class="margins">
      <div class="upload-box">
        <div class="uploader">
          <div class="form-A">
            <form @submit.prevent="upload">
              <div class="form-title">
                <h1>Nombre</h1>
                <input type="text" v-model="name"/>
              </div>
              <div class="form-titles">
                <div class="form-subtitle">
                  <h1>Profesión</h1>
                  <input type="text" v-model="profession"/>
                </div>
                <div class="form-subtitle">
                  <h1>Edad</h1>
                  <input type="text" v-model="who"/>
                </div>
              </div>
              <div class="form-message">
                <h1>Mensaje</h1>
                <textarea v-model="description" cols="30" rows="10"></textarea>
              </div>
              <div class="submit-buttons">
                <button>
                  <h1>Cancelar</h1>
                </button>
                <button class="submit-button" type="submit">
                  <h1>Publicar testimonio</h1>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blogServices } from "@/services/blogServices";

export default {
  name: "TestimonioUpload",
  data() {
    return {
      name: "",
      profession: "",
      who: "",
      description: ""
    };
  },
  computed: {
    isLogged() {
      return !!this.$store.state.accessToken;
    },
  },
  methods: {
    upload() {
  const authToken = this.$store.state.accessToken;
  if (!authToken) {
    console.error("Authentication token not found.");
    alert("Authentication token not found. Please log in again.");
    return;
  }

  // Create a JSON payload instead of FormData
  const params = {
    title: this.name,          // Assuming "title" is the correct key
    description: this.description, // Description/message
    name: this.name,           // Testimony name
    profession: this.profession, // Profession field
    who: this.who,             // Who field
    type: "testimony",         // Type of the post (testimony)
  };

  console.log("Payload:", JSON.stringify(params)); // Debugging: log the payload content

  blogServices.create_blog(params, authToken)
    .then(response => {
      console.log("Testimony uploaded successfully:", response.params);
      this.resetForm();
      alert("Testimony uploaded successfully.");
    })
    .catch(error => {
      console.error("Error uploading testimony:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        alert("Failed to upload testimony: " + JSON.stringify(error.response.data.errors));
      } else {
        alert("Failed to upload testimony. Please check the console for more details.");
      }
    });
},
    resetForm() {
      this.name = "";
      this.profession = "";
      this.who = "";
      this.description = "";
    }
  }
};
</script>

<style scoped>
.title-texts {
  display: none;
}
.title-texts h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
  letter-spacing: 0.04125rem;
}
.title-texts p {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.4625rem */
  letter-spacing: 0.03375rem;
  transition: all 0.3s ease-in-out;
}
.testimonio-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.4rem;
}
.testimonio-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 1.5rem 0rem 0rem 7.5rem;
}
.testimonio-title h1 {
  color: var(--black-black-40, #9B9B9E);
font-family: Playfair 9pt;
font-size: 1.0625rem;
font-style: normal;
font-weight: 300;
line-height: 140%; /* 1.4875rem */
}
.testimonio-title h2 {
  color: var(--primary-primary, #9E7338);
font-family: Playfair 9pt;
font-size: 1.0625rem;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 1.4875rem */
}
.testimonio-title p {
  font-family: Playfair 9pt;
font-size: 1.0625rem;
font-style: normal;
font-weight: 300;
line-height: 140%; /* 1.4875rem */
background: var(--primary-gold, linear-gradient(180deg, #9E7338 0%, #875421 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.margins {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 53.5625rem;
  flex-shrink: 0;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
.uploader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  height: 100%;
}
.form-A {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.form-A h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 0.4rem;
}
.form-A p {
  color: var(--Black-600, #717d96);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: -0.00875rem;
}
.form-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-title input {
  width: 40.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-titles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
}
.form-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-subtitle input {
  width: 18.5rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-message textarea {
  width: 40.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  resize: none;
  outline: none;
}
.submit-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 2rem;
  gap: 1rem;
  width: 81%;
  height: 100%;
}
.submit-buttons button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--black-black-40, #9b9b9e);
  background: var(--black-black, #06060c);
}
.submit-buttons button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.submit-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
@media screen and (max-width: 26.875rem){
  .vertical-line {
    width: 0.09375rem;
    height: 1.6875rem;
    background: #9e7338;
  }
  .title-texts {
    display: flex;
    align-items: center;
    gap: 1.125rem;
    margin-left: 1rem;
  }
  .testimonio-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0rem;
    gap: 0.4rem;
    margin: 0rem 0rem 0rem 1rem;
  }
  .upload-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-shrink: 0;
    padding: 1.1875rem 0rem;
    gap: 0.4rem;
    margin: 0rem 0rem 0rem 0rem;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
  }
  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    height: 100%;
    width: 100%;
  }
  .form-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-titles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
  }
  .form-A input {
    width: 17rem;
  }
  .form-A textarea{
    width: 17rem;
  }
  .submit-buttons{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  width: 80%;
  height: 80%;
}
}
</style>
