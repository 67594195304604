<template>
  <div class="homebanner">
    <swiper class="Banners"
    :space-between="10"
    :centeredSlides="true"
    :autoplay=" {delay: 3000 , disableOnInteraction: false, pauseOnMouseEnter: true}"
    :modules="modules"
    :pagination="{ clickable: true}"
    :slides-per-view="1"
    :loop="true"
    :speed="500"
  >
    <swiper-slide class="each-banner">
      <img src="@/assets/img/banner1.jpg" />
      <div class="banner-texts">
        <h1>Cómo cuidar tu perforación</h1>
        <p>y problemas comunes</p>
        <button>ver más</button>
      </div>
    </swiper-slide>
    <swiper-slide class="each-banner">
      <img src="@/assets/img/banner3.jpg" />
      <div class="banner-texts">
        <h1>Perforaciones con pistola</h1>
        <p>¿Son seguras?</p>
        <button>ver más</button>
      </div>
    </swiper-slide>
    <swiper-slide class="each-banner">
      <img src="@/assets/img/banner2.jpg" />
      <div class="banner-texts2">
        <h1>Perforaciones a recién nacidos</h1>
        <p>y porque no es recomendado</p>
        <button>ver más</button>
      </div>
    </swiper-slide>
  </swiper>
  </div>
  
</template>
  
  <script>
  import {  Pagination, Autoplay } from "swiper/modules";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/swiper-bundle.css";

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup(){
      return {
        modules: [ Pagination, Autoplay ],
      };
    },
    props: {
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    },
  };
  </script>
  
  <style src="./BannerHome.css">

  
  </style>