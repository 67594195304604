import vuex from 'vuex'

const store = new vuex.Store({
    state: {
        accessToken: localStorage.getItem('accessToken') || null, // Initialize with the value from local storage
        categoriesByType: new Map(),
        facialPiercings: [],
      },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
          },
          setUserProfileData(state, userProfileData) {
            state.userProfileData = userProfileData;
          },
          change_n(state) {
            state.n++;
          },
          setProjectData(state, projectData) {
            state.projectData = projectData;
          },
          setCategoriesByType(state, payload) {
            state.categoriesByType = payload;
          },
    },
    actions: {
        updateAccessToken({ commit }, token) {
          commit("setAccessToken", token);
        },
        change_n(context) {
          context.commit("change_n");
        },
        clearUserData({ commit }) {
          commit('setAccessToken', null);
          commit('setIsAdmin', false);
          commit('setIsSeller', false);
          commit('setUserProfileData', null);
          commit('change_n');
        },
        updateProjectData({ commit }, projectData) {
          commit('setProjectData', projectData);
        },
        updateCategoriesByType({ commit }, payload) {
          commit('setCategoriesByType', payload);
        },
    },
    getters: {
      },
})

const storedState = JSON.parse(localStorage.getItem("store"));
if (storedState) {
  store.replaceState(storedState);
}

// Subscribe to mutations and save state to localStorage
store.subscribe((mutation, state) => {
  localStorage.setItem("store", JSON.stringify(state));
  // localStorage.clear();
});

export default store

