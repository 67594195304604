<template>
  <div>
    <div
      v-for="(faq, index) in unwrappedDetail"
      :key="index"
      :class="{
        'faq-card__question': !faq.showAnswer,
        'faq-card__answer': faq.showAnswer,
        'animate-transition': faq.showAnswer || !faq.showAnswer,
      }"
      @click="toggleAnswer(faq)"
    >
      <div class="questions">
        <span class="faq-card__arrow">
          <transition name="fade">
            <img :src="dynamicImage(faq)" alt="arrow" class="transition-img" />
          </transition>
        </span>
        <h1>{{ faq.question }}</h1>
      </div>
      <div class="answers" v-if="faq.showAnswer">
        <h1>{{ faq.answer }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqCard",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  computed: {
    unwrappedDetail() {
      return Array.isArray(this.detail) ? this.detail : [];
    },
  },
  methods: {
    toggleAnswer(faq) {
      if (faq.showAnswer) {
        faq.showAnswer = false;
      } else {
        this.unwrappedDetail.forEach((item) => {
          item.showAnswer = false;
        });
        faq.showAnswer = true;
      }
    },
    dynamicImage(faq) {
      return faq.showAnswer
        ? require("@/assets/icons/openpiercing.svg")
        : require("@/assets/icons/closepiercing.svg");
    },
  },
};
</script>
<style src="./FaqCard.css"></style>
