<template>
  <div class="contact-style">
    <div class="contacto-title">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <h1>Contacto</h1>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="formulario-and-image">
      <div class="Formulario-Space">
        <div class="formulario-title">
          <p>
            Si tienes dudas o quieres hacernos algún comentario no dudes en
            ponerte en contacto.
          </p>
        </div>
        <div class="formulario-contacto">
          <form action=""  @submit.prevent>
            <div class="formulario-subdivision">
              <div class="formulario-correo">
                <h1>Correo electrónico</h1>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="correo@direccion.com"
                  v-model="input.email"
                />
              </div>
              <div class="formulario-correo">
                <h1>Nombre</h1>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Nombre Completo"
                  v-model="input.name"
                />
              </div>
            </div>
            <div class="formulario-mensaje">
              <h1>Mensaje</h1>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Escribe aquí tu duda o mensaje"
                v-model="input.message"
              ></textarea>
            </div>
          </form>
          <button type="submit" @click="handleSubmit" v-if="InputsFilled()">
            <h1>Enviar mensaje</h1>
          </button>
          <button type="submit" disabled  v-else>
            <h1>Enviar mensaje</h1>
          </button>
        </div>
      </div>
      <div class="contact-image">
        <img src="@/assets/img/contactimage.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { contactServices } from '@/services/contactServices';

export default {
  data() {
    return {
      input: {
        email: '',
        name: '',
        message: '',
      },
    };
  },
  props: {},
  computed: {},
  created() {},
  methods: {
    InputsFilled() {
      if (
        this.input.email !== "" &&
        this.input.name !== "" &&
        this.input.message !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    async handleSubmit() {
      if (this.InputsFilled()) {
        const data = {
          email: this.input.email,
          name: this.input.name,
          message: this.input.message,
        };
        try {
          const response = await contactServices.create(data);
          console.log(response);
          alert("Mensaje enviado");
        } catch (error) {
          console.log(error);
          alert("Error al enviar el mensaje");
        }
      } else {
        alert("Por favor, llena todos los campos");
      }
    },
  },
};
</script>

<style scoped>
.contact-style{
    background: var(--black-gradient, linear-gradient(180deg, #0E0E16 0%, #06060C 100%));
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.1875rem 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    overflow: hidden;
}
.contacto-title {
  display: flex;
  height: 2.5rem;
  align-items: center;
  gap: 0.625rem;
  padding: 1.1875rem 5.5rem;
}
.contacto-title img {
  display: none;
}
.contacto-title h1 {
  color: var(--black-black-10, #e7e7e7);
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.7875rem */
}
.formulario-and-image {
  display: flex;
  gap: 2rem;
  padding: 1.1875rem 5.5rem;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0;
  overflow: hidden;

}
.Formulario-Space {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.1875rem 5.5rem;
}
.formulario-title {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: auto;
}
.formulario-title p {
  color: var(--black-black-20, #cdcdce);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 185.714% */
}
.formulario-contacto {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
.formulario-contacto input {
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.3125rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  outline: none;
}
.formulario-contacto input::placeholder {
  flex: 1 0 0;
  color: var(--black-black-80, #38383d);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}
.formulario-contacto h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
}
.formulario-contacto button {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background-color: var(--black-black-90, #1d1d24);
  cursor: pointer;
}
.formulario-subdivision {
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}
.formulario-correo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
}
.formulario-mensaje {
  display: flex;
  width: 46.3125rem;
  height: 15.4375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.formulario-mensaje textarea {
  resize: none;
  height: 14rem;
  width: 100%;
  padding: 0.75rem;
  flex: 1 0 0;
  border-radius: 0.3125rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  outline: none;
}
.formulario-mensaje textarea::placeholder {
  align-self: stretch;
  color: var(--black-black-80, #38383d);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}
.contact-image {
  width: 32.1875rem;
  height: 38.625rem;
  flex-shrink: 0;
  border-radius: 0.0625rem;
}
.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
textarea, input{
  color: var(--black-black-10, #E7E7E7);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.00875rem;
}
@media screen and (max-width:26.875rem) {
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: 100%;
  }
  .contact-image {
    width: 100%;
    height: 20rem;
  }
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
@media screen and (max-width:20rem){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: auto;
  }
  .contact-image {
    width: auto;
    height: 20rem;
  }
  .contact-image img {
    width: auto;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: auto;
  }
}
@media screen and (max-width:1440px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: auto;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: auto;
  }
  .contact-image {
    width: auto;
    height: 20rem;
  }
  .contact-image img {
    width: auto;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: auto;
  }
}
@media screen and (max-width:1024px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: auto;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: auto;
  }
  .contact-image {
    width: auto;
    height: 20rem;
  }
  .contact-image img {
    width: auto;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
@media screen and (max-width:768px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: 100%;
  }
  .contact-image {
    width: 100%;
    height: 20rem;
  }
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
@media screen and (max-width:425px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: 100%;
  }
  .contact-image {
    width: 100%;
    height: 20rem;
  }
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
@media screen and (max-width:375px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: 100%;
  }
  .contact-image {
    width: 100%;
    height: 20rem;
  }
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
@media screen and (max-width:320px){
  .contacto-title{
    display: flex;
    justify-content: center;
    gap: 5rem;
  }
  .contacto-title img {
    display: flex;
  }
  .formulario-and-image {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem 1rem;

  }
  .Formulario-Space {
    padding: 1rem 1rem;
  }
  .formulario-contacto {
    width: 100%;
    gap: 1rem;
    align-items: center;
  }
  .formulario-subdivision {
    flex-direction: column;
    gap: 1rem;
  }
  .formulario-mensaje {
    width: 100%;
  }
  .contact-image {
    width: 100%;
    height: 20rem;
  }
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .formulario-contacto input{
    width: 100%;
  }
}
</style>
