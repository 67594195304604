import axios from "axios";



axios.defaults.baseURL =
  "https://floguzman.com/api/";
  // "http://floguzman.com/api/";
  // "http://localhost:8080/";
  



  const baseAxios = axios.create({
    headers: {
    // Call the generateJWT function to get the JWT
      // 'X-API-KEY': generateJWT(),
    },
  });

export default baseAxios;
