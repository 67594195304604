<template>
  <div>
    <div class="slider-title">
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="dot decoration" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="dot decoration" />
      </div>
      <div class="slider-titles">
        <p>Perforaciones</p>
      </div>
      <div class="title-dots">
        <img src="@/assets/icons/golden_dot.svg" alt="dot decoration" />
        <div class="dots-line"></div>
        <img src="@/assets/icons/golden_dot.svg" alt="dot decoration" />
      </div>
    </div>
    <div class="portafolio-subtitle">
      <h1>Portafolio</h1>
      <h2>·</h2>
      <p>Subir un trabajo</p>
    </div>
    <div class="upload-box">
      <form @submit.prevent="upload" enctype="multipart/form-data">
        <div class="uploaders">
          <div class="form-A">
            <div class="form-title">
              <h1>Titulo</h1>
              <input type="text" placeholder="Título o descripción del trabajo" v-model="title" />
            </div>
            <div class="form-select">
              <h1>Categoría</h1>
              <select name="category" id="category" v-model="selectedCategory">
                <option v-for="(subcategories, category) in category.detail" :key="category" :value="category">
                  {{ category }}
                </option>
              </select>
            </div>
            <div class="form-select">
              <h1>Subcategoría</h1>
              <select name="subcategory" id="subcategory" v-model="selectedSubcategory">
                <option v-for="(subItem, index) in subcategory" :key="index" :value="subItem">
                  {{ subItem }}
                </option>
              </select>
            </div>
            <div class="form-title">
              <h1>Etiquetas</h1>
              <input type="text" v-model="tags" />
            </div>
          </div>
          <div class="picture-upload">
            <div class="picture-title">
              <h1>Imagen de portada</h1>
            </div>
            <div class="dropzone" @dragover.prevent @dragenter.prevent @dragstart.prevent @drop.prevent="handleFileChange($event.dataTransfer)">
              <input
  id="file-input"
  type="file"
  accept="image/png, image/jpeg"
  @change="handleFileChange"
  required
/>
              <img v-if="preview" :src="preview" />
            </div>
          </div>
        </div>
        <div class="submit-buttons">
          <button type="button" @click="resetForm"><h1>Cancelar</h1></button>
          <button type="submit" class="submit-button" v-if="isLogged"><h1>Publicar trabajo</h1></button>
          <button type="button" class="submit-button" v-else><h1>Publicar trabajo</h1></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { piercingServices } from "@/services/piercingServices";

export default {
  name: "PortafolioUpload",
  data() {
    return {
      title: "",
      tags: "",
      file: null,
      preview: null,
      formData: null,
      category: [],
      subcategory: [],
      selectedCategory: null,
      selectedSubcategory: null,
    };
  },
  computed: {
    isLogged() {
      return !!this.$store.state.accessToken;
    },
  },
  methods: {
    handleFileChange(event) {
  // First, let's log the event to see what we're getting
  console.log(event);

  // Make sure we're accessing the files correctly
  const files = event.target.files || (event.dataTransfer ? event.dataTransfer.files : null);
  if (!files || files.length === 0) {
    console.error("No file selected or file access error.");
    return;
  }

  const file = files[0];
  this.file = file;

  const reader = new FileReader();
  reader.onload = () => {
    this.preview = reader.result;
  };
  reader.readAsDataURL(file);
},
    Categories() {
      piercingServices.categories_subcategories()
        .then(response => {
          this.category = response;
        })
        .catch(error => {
          console.error("Failed to load categories:", error);
        });
    },
    upload() {
    // Ensure that the authToken is retrieved correctly as a string
    const authToken = this.$store.state.accessToken;
    
    if (!authToken || typeof authToken !== 'string') {
      console.error("Authentication token is invalid or not a string:", authToken);
      alert("Authentication token not found or invalid. Please log in again.");
      return;
    }



    // Validate the required fields
    if (!this.title || !this.selectedCategory || !this.selectedSubcategory || !this.file) {
      console.error("Incomplete data for upload.");
      alert("Incomplete data; please fill all fields.");
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append("title_description", this.title);
    formData.append("category", this.selectedCategory);
    formData.append("subcategory", this.selectedSubcategory);
    formData.append("piercing_photo", this.file);

    // Send the request to upload the portfolio entry
    piercingServices.create_piercing(formData, authToken)
      .then(response => {
        console.log("Portfolio entry uploaded successfully:", response);
        this.resetForm();
      })
      .catch(error => {
        console.error("Error uploading portfolio entry:", error);
        alert('Error uploading portfolio entry: Check console for details.');
        if (error.response) {
          console.error("Detailed error response:", error.response.data);
        }
      });
  },


    resetForm() {
      this.title = "";
      this.tags = "";
      this.file = null;
      this.preview = null;
      this.formData = null;
      this.selectedCategory = null;
      this.selectedSubcategory = null;
    },
  },
  mounted() {
    this.Categories();
  },
  watch: {
    selectedCategory(newCategory, oldCategory) {
      if (newCategory !== oldCategory) {
        this.subcategory = this.category.detail[newCategory] || [];
        this.selectedSubcategory = null;
      }
    },
  },
};
</script>



<style scoped>
.slider-title {
  display: none;
}
.portafolio-subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 1.5rem 0rem 0rem 7.5rem;
}
.portafolio-subtitle h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle h2 {
  color: var(--primary-primary, #9e7338);
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle p {
  color: var(--primary-primary, #9e7338);
  font-family: "Playfair 9pt";
}
.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 81.5rem;
  flex-shrink: 0;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 0rem 0rem 4rem 7.5rem;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
.uploaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  height: 100%;
}
.form-A {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
}
.form-A h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 0.4rem;
}
.form-A input {
  width: 20.25rem;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-A select {
  width: 100%;
  padding: 1.125rem 1.25rem;
  border-radius: 0.375rem;
  border: 1px solid var(--black-black-90, #1d1d24);
  background: rgba(29, 29, 36, 0.5);
  color: var(--black-black-40, #9b9b9e);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 1rem;
  outline: none;
}
.form-A option {
  color: var(--black-black-40, #06060c);
  background-color: #cdcdce;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
}

.form-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.form-select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.4rem;
  width: 100%;
}
.picture-upload {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  padding: 1.1875rem 0rem;
  margin: 0rem 0rem 0rem 7.5rem;
}
.picture-upload h1 {
  color: var(--black-black-40, #9b9b9e);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.0075rem;
  margin-bottom: 0.6rem;
}
.dropzone {
  width: 37.25rem;
  height: 24rem;
  border-radius: 0.0625rem;
  border: 1px solid var(--black-black-80, #38383d);
  background: #06060c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  width: inherit;
  height: inherit;
  cursor: pointer;
}
img {
  object-fit: contain;
  overflow: hidden;
}
.submit-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 91%;
  height: 80%;
}
.submit-buttons button {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--black-black-40, #9b9b9e);
  background: var(--black-black, #06060c);
}
.submit-buttons button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.submit-button {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
}
button:hover {
  background: var(--primary-gold, #9e7338);
  background: linear-gradient(
    180deg,
    rgba(158, 115, 56, 0.5) 0%,
    rgba(135, 84, 33, 0.5) 100%
  );
}
@media screen and (max-width: 26.875rem) {
  .slider-title {
    display: flex;
  }
  .portafolio-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0rem;
    gap: 1.5rem;
    margin: 0rem 0rem 0rem 2rem;
  }
  .portafolio-subtitle h1 {
    color: var(--black-black-40, #9b9b9e);
    font-family: "Playfair 9pt";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 1.4875rem */
  }
  .upload-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 0.4rem;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    margin: 0rem 0rem 0rem 1rem;
  }
  .uploaders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    height: 100%;
    width: 100%;
  }
  .form-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-A h1 {
    color: var(--black-black-40, #9b9b9e);
    font-feature-settings: "calt" off;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 133.333% */
    letter-spacing: -0.0075rem;
    margin-bottom: 0.4rem;
  }
  .form-A input {
    width: 17rem;
  }
  .form-A select {
    width: 100%;
  }
  .picture-upload {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin: 0rem 0rem 0rem 0rem;
  }
  .picture-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2.5rem;
  }
  .dropzone {
    width: 80%;
    height: 24rem;
    border-radius: 0.0625rem;
    border: 1px solid var(--black-black-80, #38383d);
    background: #06060c;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input[type="file"] {
    position: absolute;
    opacity: 0;
    width: inherit;
    height: inherit;
    cursor: pointer;
  }
  .submit-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    width: 80%;
    height: 80%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
}
</style>
