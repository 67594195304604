import {createRouter, createWebHistory} from 'vue-router';
import HomeDesktop from './views/HomeDesktop.vue';
import PortafolioView from './views/PortafolioView.vue';
import PortafolioOreja from './views/PortafolioOreja.vue';
import PortafolioFacial from './views/PortafolioFacial.vue';
import PortafolioCorporal from './views/PortafolioCorporal.vue';
import PortafolioGallery from './views/PortafolioGallery.vue';
import AboutView from './views/AboutView.vue';  
import ContactView from './views/ContactView.vue';  
import CuidadosPreguntas from './views/CuidadosPreguntas.vue';
import LoginView from './views/LoginView.vue';
import PortafolioUpload from './views/PortafolioUpload.vue';
import BlogUpload from './views/BlogUpload.vue';
import TestimonioUpload from './views/TestimonioUpload.vue';
import BlogView from './views/BlogView.vue';
import TestimonioView from './views/TestimonioView.vue';
import BlogDetail from './views/BlogDetail.vue';
import BusquedaView from './views/BusquedaView.vue';


const routes = [
    { path: '/', 
    component: HomeDesktop,
    name: 'Home' 
    },
    { path: '/portafolio',
    component: PortafolioView,
    name: 'Portafolio' 
    },
    { path: '/portafolio/orejas',
    component: PortafolioOreja,
    name: 'Oreja' 
    },
    { path: '/portafolio/faciales',
    component: PortafolioFacial,
    name: 'Facial' 
    },
    { path: '/portafolio/corporales',
    component: PortafolioCorporal,
    name: 'Corporal' 
    },
    { path: '/portafolio/gallery/:subcategory',
    component: PortafolioGallery,
    name: 'Gallery' 
    },
    { path: '/about',
    component: AboutView,
    name: 'About' 
    },
    { path: '/contacto',
    component: ContactView,
    name: 'Contact' 
    },
    { path: '/cuidados',
    component: CuidadosPreguntas,
    name: 'Cuidados' 
    },
    { path: '/login',
    component: LoginView,
    name: 'Login'
    },
    { path: '/portafolio/upload',
    component: PortafolioUpload,
    name: 'Portafolio_Upload'
    },
    { path: '/blog_upload',
    component: BlogUpload,
    name: 'Blog_Upload'
    },
    { path: '/testimonio_upload',
    component: TestimonioUpload,
    name: 'Testimonio_Upload'
    },
    { path: '/blog',
    component: BlogView,
    name: 'Blog'
    },
    { path: '/testimonios',
    component: TestimonioView,
    name: 'Testimonios'
    },
    { path: '/blog/:id',
    component: BlogDetail,
    name: 'Blog_Detail'
    },
    { path: '/busqueda',
    component: BusquedaView,
    name: 'Busqueda'
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    base: process.env.BASE_URL,
    routes
  })

  export default router;