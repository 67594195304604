<template>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <h1>Portafolio</h1>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div class="portafolio-subtitle">
    <router-link to="/portafolio" style="text-decoration: none">
      <h1>Perforaciones</h1>
    </router-link>
    <h2>·</h2>
    <p>Corporales</p>
  </div>
  <div class="portafolio-cards">
    <div
      v-for="subcategory in uniqueSubcategories"
      :key="subcategory"
      class="portafolio-card-wrapper"
    >
      <router-link
        :to="'/portafolio/gallery/' + subcategory.toLowerCase()"
        style="text-decoration: none"
        v-if="subcategory !== 'Genitales'"
      >
        <div
          class="portafolio-card"
          @mouseenter="function1"
          @mouseleave="function2"
        >
          <div class="portafolio-card-title">
            <h1>{{ subcategory }}</h1>
          </div>
          <div class="portafolio-card-image">
            <div :class="{'blurred-image': subcategory === 'Genitales'}">
              <img :src="getPiercingPhotoUrl(subcategory)" alt="" />
            </div>
          </div>
        </div>
      </router-link>
      <div
        v-else
        class="portafolio-card"
        @mouseenter="function1"
        @mouseleave="function2"
        @click="showModal = true"
      >
        <div class="portafolio-card-title">
          <h1>{{ subcategory }}</h1>
        </div>
        <div class="portafolio-card-image">
          <div :class="{'blurred-image': subcategory === 'Genitales'}">
            <img :src="getPiercingPhotoUrl(subcategory)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal" class="modal" @click="showModal = false">
    <div class="modal-content" @click.stop>
      <h2>Advertencia</h2>
      <p>
        Contenido explícito de perforaciones genitales solo para mayores de edad.
        Si eres menor o te incomoda, abandona la página. Queremos un espacio
        respetuoso para todos. Gracias por comprender.
      </p>
      <div class="modal-buttons">
        <button @click="showModal = false">Cancelar</button>
        <button @click="proceedToGallery">Confirmar</button>
      </div>
    </div>
  </div>
</template>


<script>
import { piercingServices } from "@/services/piercingServices";

export default {
  data() {
    return {
      isHovered: false,
      showModal: false,
    };
  },
  computed: {
    corporalPiercings() {
      return this.$store.state.categoriesByType["Corporales"] || [];
    },
    uniqueSubcategories() {
      return [
        ...new Set(
          this.corporalPiercings.map((piercing) => piercing.subcategory)
        ),
      ];
    },
  },
  mounted() {
    this.listPiercings();
  },
  methods: {
    function1() {
      this.isHovered = true;
    },
    function2() {
      this.isHovered = false;
    },
    listPiercings() {
      piercingServices
        .list_piercings()
        .then((response) => {
          if (response.detail && Array.isArray(response.detail)) {
            const corporalesData = response.detail.filter(
              (item) => item.category === "Corporales"
            );
            if (corporalesData.length > 0) {
              this.$store.dispatch("updateCategoriesByType", {
                Corporales: corporalesData,
              });
            }
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    getPiercingPhotoUrl(subcategory) {
      const piercing = this.corporalPiercings.find(
        (p) => p.subcategory === subcategory
      );
      return piercing ? piercing.photo_url : "";
    },
    proceedToGallery() {
      this.showModal = false;
      this.$router.push('/portafolio/gallery/genitales');
    }
  },
};
</script>

<style scoped>
.portafolio-subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 0.4rem;
  margin: 0rem 0rem 0rem 7.5rem;
}
.portafolio-subtitle h1 {
  color: var(--black-black-40, #9b9b9e);
  font-family: Playfair 9pt;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle h2 {
  color: var(--primary-primary, #9e7338);
  font-family: Playfair 9pt;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-subtitle p {
  color: var(--primary-primary, #9e7338);
}
.portafolio-cards {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  padding: 1.1875rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}
.portafolio-card {
  width: 21.75rem;
  height: 23.75rem;
  flex-shrink: 0;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.portafolio-card:hover {
  transition: all 0.5s ease-in-out;
}
.portafolio-card-title {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  border-bottom: 1px solid var(--black-black-90, #1d1d24);
}
.portafolio-card-title h1 {
  align-self: stretch;
  color: var(--black-black-10, #e7e7e7);
  /* Titulo card */
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.625rem */
}
.portafolio-card-image {
  width: 100%;
  height: 100%;
}
.portafolio-cards img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blurred-image {
  position: relative;
}
.blurred-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.3);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  max-width: 500px;
  width: 90%;
  font-family: 'Poppins';
}

.modal-content h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 1.25rem;
}

.modal-buttons button:first-child {
  background: transparent;
  color: #ffffff;
}

.modal-buttons button:last-child {
  background: transparent;
  color: #ffffff;
  border: 1px solid var(--primary-gold, #9E7338);
}

@media screen and (max-width: 26.875rem) {
  .portafolio-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0rem;
    gap: 1.5rem;
    margin: 0rem 0rem 0rem 2rem;
  }
  .portafolio-subtitle h1 {
    color: var(--black-black-40, #9b9b9e);
    font-family: "Playfair 9pt";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 1.4875rem */
  }
  .portafolio-cards {
    display: flex;
    padding: 1.1875rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-direction: column;
  }
  .portafolio-card {
    width: 24.1875rem;
    height: 26.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:20rem) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:1440px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:1024px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
  .portafolio-cards {
    display: flex;
    padding: 1.1875rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-direction: column;
  }
}
@media screen and (max-width:768px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var (--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:425px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var (--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:375px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var (--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:320px) {
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var (--black-black, #06060c);
    overflow: hidden;
  }
}
</style>

