<template>
  <div class="slider-title">
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
    <div class="slider-titles">
      <h1>Portafolio</h1>
      <div v-if="isLogged">
        <router-link to="/portafolio/upload" style="text-decoration: none">
          <button>
            <h1>Subir trabajo</h1>
          </button>
        </router-link>
      </div>
      <div v-else></div>
    </div>
    <div class="title-dots">
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
      <div class="dots-line"></div>
      <img src="@/assets/icons/golden_dot.svg" alt="ear" />
    </div>
  </div>
  <div class="portafolio-subtitle">
    <h1>Perforaciones</h1>
  </div>
  <div class="portafolio-cards">
    <router-link to="/portafolio/orejas" style="text-decoration: none">
      <div
        class="portafolio-card"
        @mouseenter="function1('oreja')"
        @mouseleave="function2('oreja')"
      >
        <div class="portafolio-card-title">
          <h1>Orejas</h1>
        </div>
        <div class="portafolio-card-image">
          <img src="@/assets/img/portafolio-oreja.jpg" alt="" />
          <div class="blurr-padding" v-if="isHovered['oreja']">
            <div class="portafolio-card-hovered">
              <h1>Combinaciones</h1>
              <h1>Conch</h1>
              <h1>Daith</h1>
              <h1>Expansiones</h1>
              <h1>Flat</h1>
              <h1>Helix</h1>
              <h1>Lobe</h1>
              <h1>Rook</h1>
              <h1>Tragus</h1>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <router-link to="/portafolio/faciales" style="text-decoration: none">
      <div
        class="portafolio-card"
        @mouseenter="function1('facial')"
        @mouseleave="function2('facial')"
      >
        <div class="portafolio-card-title">
          <h1>Faciales</h1>
        </div>
        <div class="portafolio-card-image">
          <img src="@/assets/img/portafolio-facial.jpg" alt="" />
          <div class="blurr-padding" v-if="isHovered['facial']">
            <div class="portafolio-card-hovered">
              <h1>Bridge</h1>
              <h1>Eyebrow</h1>
              <h1>Labret</h1>
              <h1>Nostril</h1>
              <h1>Philtrum</h1>
              <h1>Septum</h1>
              <h1>Surface</h1>
              <h1>Tongue</h1>
              <h1>Vertical Labret</h1>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <router-link to="/portafolio/corporales" style="text-decoration: none">
      <div
        class="portafolio-card"
        @mouseenter="function1('corporal')"
        @mouseleave="function2('corporal')"
      >
        <div class="portafolio-card-title">
          <h1>Corporales</h1>
        </div>
        <div class="portafolio-card-image">
          <img src="@/assets/img/portafolio-corporal.jpg" alt="" />
          <div class="blurr-padding" v-if="isHovered['corporal']">
            <div class="portafolio-card-hovered">
              <h1>Floating Navel</h1>
              <h1>Navel</h1>
              <h1>Nipples</h1>
              <h1>Genitales</h1>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { piercingServices } from "@/services/piercingServices";

export default {
  components: {},
  setup() {
    return {};
  },
  props: {},
  data() {
    return {
      isHovered: {
        oreja: false,
        facial: false,
        corporal: false,
      },
      piercings: [],
    };
  },
  mounted() {
    this.listPiercings();
  },
  computed: {
    isLogged: function () {
      if (this.$store.state.accessToken !== null) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    function1(categoria) {
      this.isHovered[categoria] = true;
    },
    function2(categoria) {
      this.isHovered[categoria] = false;
    },
    listPiercings(params) {
      piercingServices
        .list_piercings(params)
        .then((response) => {
          this.piercings = response.detail;
          this.sortPiercingsByCategory(); // Add this line to sort the piercings

          // Dispatch the action to update the store
          this.$store.dispatch("updateCategoriesByType", { category: 'Faciales', piercings: this.piercings.filter(p => p.category === 'Faciales') });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortPiercingsByCategory() {
      const categoryOrder = { oreja: 0, facial: 1, corporal: 2 }; // Define the order of categories

      // Sort the piercings array based on the 'category' property
      this.piercings.sort((a, b) => {
        const categoryA = a.category.toLowerCase(); // Convert to lowercase to make it case-insensitive
        const categoryB = b.category.toLowerCase();

        const orderA = categoryOrder[categoryA];
        const orderB = categoryOrder[categoryB];

        return orderA - orderB;
      });

      // Now, you can use a Map to group piercings by category
      const piercingsByCategory = new Map();
      this.piercings.forEach((piercing) => {
        const category = piercing.category;
        if (!piercingsByCategory.has(category)) {
          piercingsByCategory.set(category, []);
        }
        piercingsByCategory.get(category).push(piercing);
      });
      // Now piercingsByCategory is a Map where keys are categories, and values are arrays of piercings in that category
      this.$store.dispatch("updateCategoriesByType", piercingsByCategory);
    },

  },
};
</script>

<style scoped>
.slider-titles button {
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
  margin-left: 1rem;
  cursor: pointer;
}
.slider-titles button h1 {
  color: var(--black-black-20, #cdcdce);
  font-feature-settings: "calt" off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}
.portafolio-subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.1875rem 0rem;
  gap: 1.5rem;
  margin: 0rem 0rem 0rem 7.5rem;
}
.portafolio-subtitle h1 {
  color: var(--primary-primary, #9e7338);
  font-family: "Playfair 9pt";
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4875rem */
}
.portafolio-cards {
  display: flex;
  padding: 1.1875rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 5.5rem;
}
.portafolio-card {
  width: 25.1875rem;
  height: 27.5rem;
  flex-shrink: 0;
  border-radius: 0.0625rem;
  border: 1px solid var(--primary-gold, #9e7338);
  background: var(--black-black, #06060c);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.portafolio-card:hover {
  transition: all 0.5s ease-in-out;
}
.portafolio-card-title {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  border-bottom: 1px solid var(--black-black-90, #1d1d24);
}
.portafolio-card-title h1 {
  align-self: stretch;
  color: var(--black-black-10, #e7e7e7);
  /* Titulo card */
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 1.625rem */
}
.portafolio-card-image {
  width: 100%;
  height: 100%;
}
.portafolio-cards img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blurr-padding {
  transform: translate(0px, -115%);
  position: relative;
  backdrop-filter: contrast(86%) brightness(50%);
  padding: 2rem 2rem;
}
.portafolio-card-hovered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blurr-padding h1 {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 200%; /* 2.25rem */
}
@media screen and (max-width: 26.875rem) {
  .portafolio-subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.1875rem 0rem;
    gap: 1.5rem;
    margin: 0rem 0rem 0rem 2rem;
  }
  .portafolio-subtitle h1 {
    color: var(--primary-primary, #9e7338);
    font-family: Playfair 9pt;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 1.4875rem */
  }
  .portafolio-cards {
    display: flex;
    padding: 1.1875rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-direction: column;
  }
  .portafolio-card {
    width: 24.1875rem;
    height: 26.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:20rem){
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }

}
@media screen and (max-width:1440px){
  .portafolio-card {
    width: 20.1875rem;
    height: 22.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:1024px){
  .portafolio-card {
    width: 18.1875rem;
    height: 20.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
  .portafolio-cards {
    display: flex;
    padding: 1.1875rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-direction: column;
  
}
}
@media screen and (max-width:768px){
  .portafolio-card {
    width: 16.1875rem;
    height: 18.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:425px){
  .portafolio-card {
    width: 14.1875rem;
    height: 16.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:375px){
  .portafolio-card {
    width: 12.1875rem;
    height: 14.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
@media screen and (max-width:320px){
  .portafolio-card {
    width: 10.1875rem;
    height: 12.5rem;
    flex-shrink: 0;
    border-radius: 0.0625rem;
    border: 1px solid var(--primary-gold, #9e7338);
    background: var(--black-black, #06060c);
    overflow: hidden;
  }
}
</style>
